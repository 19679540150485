import { Button } from 'antd';
import {useHistory} from 'react-router-dom'
import React, { useState } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Description = ({ desc , id , title }) => {
  const [showMore, setShowMore] = useState(false);
  const history = useHistory()

  const toggleShowMore = () => {
    if(id){
      if(title === "polyline"){

        history.push(`/mapdand/line/${id}`)
      }else{
        history.push(`/mapdand/plot/${id}`)
      }
    }
    setShowMore(!showMore);
  };

  const wordLimit = 20;
  const words = desc.split(' ');
  const isLong = words.length > wordLimit;
  const displayedDesc = isLong && !showMore ? words.slice(0, wordLimit).join(' ') + '....' : desc;

  return (
    <div>
      <ReactMarkdown className="maapdand-details" remarkPlugins={[remarkGfm]}>
        {displayedDesc}
      </ReactMarkdown>
      {isLong && (
        <Button className='mapdand-desc-show' style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}  onClick={toggleShowMore}>
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  );
};

export default Description;