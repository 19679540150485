import axios from 'axios';


export const uploadPolyline = async (polylineData , token) => {

    try {
        
        const response = await axios.post(
            '/api/upload-polyline',
            polylineData, // Provide an object with the data you want to send in the request body
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
       
        return response.data;
    } catch (error) {
       
        console.error(error);
        
    }
};

export const allPolylines= async () => {
  try {
      const response = await axios.get('/api/all-polyline');

     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};


export const deletePolyline = async(id, token) => {


  try {
      const response = await axios.delete(`/api/delete-polyline/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      return response.data;
  } catch (error) {
      return error.response.data;
  }

}


export const polyline= async (slug) => {
  try {
      const response = await axios.get(`/api/polyline/${slug}`);
      
     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};

export const updatePolyline = async (slug , polylineData , token) => {

  try {
      
      const response = await axios.put(
          `/api/update-polyline/${slug}`,
          polylineData, // Provide an object with the data you want to send in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};