import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Menu from '../Menu/Menu'
import MetaData from '../../MetaData'
import { useParams } from 'react-router-dom';
import {  toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';
import { Button, Input, Modal } from 'antd';
import ImageModel from '../ModelImage/ImageModel';
import TextArea from 'antd/es/input/TextArea';
import { polyline, updatePolyline } from '../../action/polyline';

function MapdandSingleUpdate({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  const [polylineImages, setPolylineImages] = useState([])
  const [polylineTitle, setPolylineTitle] = useState("")
  const { slug } = useParams();
  const [photoVisible, setPhotoVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [photoPolygonVisible, setPhotoPolygonVisible] = useState(false);
  const [polylineDescVisible, setPolylineDescVisible] = useState(false)
  const [polylineDesc, setPolylineDesc] = useState("")
  const [polylineWidth, setPolylineWidth] = useState(2)
  const [linewidth, setLinewidth] = useState(0)
  const [polylineColor, setPolylineColor] = useState("blue")
  const [inputValue, setInputValue] = useState("");
  const [zoomPolyline, setZoomPolyline] = useState(2)

  const handleLinePressEnter = () => {
    setPolylineWidth(linewidth);
    setLinewidth(0)
  };

  const handlePressEnter = () => {
    setPolylineColor(inputValue);
    setInputValue("")
  };

  useEffect(() => {
    const isAuthInvalid = !auth 
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/tst-admin');
        return;
      }
    } else {
      history.push('/tst-admin');
    }
  }, [auth]);

  useEffect(() => {


    loadPolyline();

}, []);



const loadPolyline = async () => {
try {
    const singlePolyline = await polyline(slug);
    console.log(singlePolyline);

    // Destructure the properties you need from singlePolyline
    const {
        color,
        zoom,
        desc,
        images,
        title,
        width,
      
    } = singlePolyline;

    // Populate the state variables accordingly
  
    setPolylineTitle(title);
    setPolylineImages(images);
    setZoomPolyline(zoom)

    setPolylineDesc(desc);
    setPolylineColor(color || 'blue'); // Default to 'blue' if color is not provided
    setPolylineWidth(width)
    

} catch (error) {
    console.error('Error loading property:', error);
}
};

const handlePolylineSubmit = async(e) => {
  e.preventDefault();

  
  if (
  
    polylineTitle.trim() === "" ||

    

    polylineDesc.trim() === "" ||
    polylineWidth === 0 ||
    polylineDesc.trim() === ""
  ) {
    alert("Please fill in all the fields.");
    return;
  }
  const polylineData = {
    title: polylineTitle,
    images: polylineImages,  // Make sure images are in the right format (e.g., URLs or base64)
     // Ensure video is in the correct format (e.g., URL)
     zoom: zoomPolyline ,
    desc: polylineDesc,

    color: polylineColor,  // Assuming this is the color you set
    width: polylineWidth ,
  
  };


  try {
    const updateData = await updatePolyline(slug , polylineData , auth?.token )
    if (updateData) {
      toast.success('Polyline updated successfully!');
      // Clear all form fields
      history.push("/dashboard/mapdand-upload")
    }
  } catch (error) {
    toast.error('Failed to upload property. Please try again.');
  }
};

const handleZoomPolylineInputChange = (e) => {
  const value = e.target.value;
  const numericValue = parseInt(value, 10);

  if (numericValue >= 1 && numericValue <= 20) {
    setZoomPolyline(numericValue);
  }
};

  
  return (
    <>
    <MetaData title="Dashboard" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items">
            <h2>Title</h2>
            <div className="polyline-update">
                  <h3>Polyline</h3>
                 
                  <div className="mapdandUd-polyline-details">
                  <Input className='mapdandUd-btn' value={polylineWidth}
                  onChange={(e) => setPolylineWidth(e.target.value)}
                  
                  placeholder='Width'/>
                  <Input className='mapdandUd-btn' value={polylineColor} onChange={(e) => setPolylineColor(e.target.value)}
                 
                  placeholder='Color' />
                  </div>
                  <Input type="number" name="" max={20} min={1} value={zoomPolyline}   onChange={handleZoomPolylineInputChange}
                  className='form-input' placeholder='Zoom Level' id="" />
                  <Input type="text" name="" value={polylineTitle} onChange={(e) => setPolylineTitle(e.target.value)}
                  className='form-input' placeholder='Title' id="" />
                  <div className="mapdandUd-polyline-details">
                  <Button className='mapdandUd-btn' onClick={() => setPolylineDescVisible(true)} >
                    Polyline Details
                  </Button>
                  <Button className='mapdandUd-btn' onClick={() => setPhotoVisible(true)}>
                    Upload Photo
                  </Button>
                  </div>
                  <Button className='form-submit' onClick={handlePolylineSubmit}>Submit</Button>
                </div>
          </div>
        </div>
    </div>
    <ImageModel 
      photoVisible={photoVisible} 
      setPhotoVisible={setPhotoVisible} 
      images={polylineImages} 
      setImages={setPolylineImages} 
      loading={loading}
      setLoading={setLoading}
      slug={polylineTitle}
      id={slug}   
      />
    <Modal
              title="Description"
              centered
              open={polylineDescVisible}
              onCancel={() => setPolylineDescVisible(false)}
              footer={null}
            >
                <TextArea className='mandand-description' cols={10} rows={10} value={polylineDesc}
                onChange={(e) => setPolylineDesc(e.target.value)}
                placeholder='Enter Polyline Description'/>
            </Modal>
    </>
  )
}

export default MapdandSingleUpdate