import React, { useState } from 'react'
import { FaLocationDot , FaArrowRightLong } from "react-icons/fa6";
import property1 from '../../assests/property/1.png'
import property2 from '../../assests/property/2.png'
import property3 from '../../assests/property/3.png'
import MetaData from "../../MetaData";
import property4 from '../../assests/property/4.png'
import property5 from '../../assests/property/5.jpg'
import property6 from '../../assests/property/6.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactGA from "react-ga4";
import { allProperties  } from "../../action/property";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination } from 'swiper/modules';
import './propertylist.css'
import { useEffect } from 'react';
import HomeDesc from '../Home/HomeDesc';



function PropertyList({history}) {
  const [properties, setProperties] = useState([])

  const handleNewarraivalClick = (id, title) => {
    // Send event to Google Analytics
    ReactGA.event({
      category: "Property",
      action: "Clicked on Property Card",
      label: title, // Optional: Use the property title to identify which card was clicked
      value: 1, // Optional: Use a value if you want to quantify the event
    });
  
    // Navigate to the property detail page
    history.push(`/property/${id}`);
    console.log(title);
  };
  
  useEffect(() => {
    
    loadPhotoAggregate()
  
}, [])

const loadPhotoAggregate = async() =>{

  try {
    const singlePhotoCategory = await allProperties()
  


  setProperties(singlePhotoCategory);
  
} catch (error) {
  
}
}


const handleContactClick = () => {

  history.push("/contact")
 

};
console.log(properties)
  return (
    <>
    <MetaData title="Property list" />
       <div className='propertylist-model'>
      <h1>Property list</h1>
      <div className="properties-container">
      {
                  properties?.map(n => ( 
              <div className= {`cardRe ${n?.sold ? "sale" : ""}`}>
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {
                          n?.images?.map(i => (
                            <SwiperSlide >
                              <img src={i?.Location} alt={n?.title} />
                            </SwiperSlide>
                          ))
                        }
                       
                      </Swiper>
               
                <div className="locationRe">
                  <h3>{n?.location}</h3>
                  <FaLocationDot className='locationIcon'/>
                </div>
                <div className="descRe">
                  <HomeDesc desc={n?.desc}/>
                
                </div>
                <hr />
                <div className="cardRe-buttons">
                  <button className='white-btn' onClick={() => handleNewarraivalClick(n?._id , n?.title)}>Show Details</button>
                  <button className='primary-btn' onClick={() => handleContactClick()}>
                    Book a Tour <FaArrowRightLong/>
                  </button>
                </div>
              </div>
                 ))
                }
              
      </div>
    </div>
    {/* <h2 >{n?.sold ? "sold" : "for sale"}  </h2> */}
    </>
   
  )
}

export default PropertyList