import React, {useRef, useEffect, useState } from 'react';
import { FaLocationDot ,FaInstagram, FaWhatsapp ,FaArrowRightLong } from "react-icons/fa6";
import { CAlert , CAccordion , CAccordionItem, CAccordionHeader,  CAccordionBody} from '@coreui/react';
import { Pagination as AntdPagination } from 'antd';
import '@coreui/coreui/dist/css/coreui.min.css'
import { FaArrowDownLong } from "react-icons/fa6";
import ReactGA from "react-ga4";
import banner from '../../assests/banner.png'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { motion ,  useAnimation} from "framer-motion";
import property6 from '../../assests/property/6.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineEnter } from "react-icons/ai";
import MetaData from "../../MetaData";
import ReactMarkdown from 'react-markdown';
import HomeDesc from './HomeDesc.jsx'
import jagga from '../../assests/homeslider/jagga.jpeg'
import jagga1 from '../../assests/homeslider/jagga1.jpeg'
import anka from '../../assests/homeslider/anka.png'
import construct from '../../assests/homeslider/construct.jpeg'
import plotdesign from '../../assests/homeslider/plotdesign.png'
import survey from '../../assests/homeslider/survey.png'
import servicespic from '../../assests/homeslider/services.jpg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Navigation, Thumbs ,Autoplay ,Pagination,EffectFade} from 'swiper/modules';


// import required modules

import './home.css'
import { getProducts, getPropertyCount, getSoldProducts } from '../../action/property';


const mark = `Peaceful residential area

3.1 km from satdobato ringroad

Rapidly developing area

High return on investment`

const customVars = {
  '--cui-accordion-active-color': 'inherit',  // No text color change on active
  '--cui-accordion-active-bg': 'inherit',     // No background color change on active
  '--cui-accordion-btn-focus-box-shadow': 'inherit',   

};

function Home({history}) {
 
  const [textContent, setTextContent] = useState('TST REAL ESTATE');

  const [currentSoldPage, setCurrentSoldPage] = useState(1);
   
    const [translatePercentage, setTranslatePercentage] = useState(25);
      const [page, setPage] = useState(1);
      const [pageSold, setPageSold] = useState(1);
      const [perPage, setPerPage] = useState(3)
      const [unsoldTotal, setUnsoldTotal] = useState(1)
      const [products, setProducts] = useState([]);
      const [soldTotal, setSoldTotal] = useState(1)
      const [productsSold, setProductsSold] = useState([]);
      const [loading, setLoading] = useState(false);
      const [showMore, setShowMore] = useState(false);
      const wordLimit = 15;
      const words = mark.split(' ');
      const isLong = words.length > wordLimit;

    console.log(page)
    useEffect(() => {
      const updateItemsPerPage = () => {
        if (window.innerWidth <= 700) {
          setPerPage(1)
        } else {
          setPerPage(3)
        }
      };
  
      // Initial check
      updateItemsPerPage();
  
      // Update on window resize
      window.addEventListener('resize', updateItemsPerPage);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', updateItemsPerPage);
    }, [ page , pageSold]);

    useEffect(() => {
      loadAllProducts();
      loadAllSoldProducts()
      propertyCount()
    }, [page , pageSold]);

    const loadAllProducts = () => {
      setLoading(true);
      // sort, order, limit
      getProducts("createdAt", "desc", page , perPage ).then((res) => {
        setProducts(res.data);
        setLoading(false);
      });
    };

    console.log(products)

    const propertyCount = async () => {
      try {
        const { sold, notSold } = await getPropertyCount();
        setSoldTotal(sold);
        setUnsoldTotal(notSold);
      } catch (error) {
        console.error('Error fetching property counts:', error);
      }
    };

    const loadAllSoldProducts = () => {
      setLoading(true);
      // sort, order, limit
      getSoldProducts("createdAt", "desc", pageSold , perPage ).then((res) => {
        setProductsSold(res.data);
        setLoading(false);
      });
    };

    
    console.log(products)


    const handleNewarraivalClick = (id, title) => {
      // Send event to Google Analytics
      ReactGA.event({
        category: "Property",
        action: "Clicked on Property Card",
        label: title, // Optional: Use the property title to identify which card was clicked
        value: 1, // Optional: Use a value if you want to quantify the event
      });
    
      // Navigate to the property detail page
      history.push(`/property/${id}`);
      console.log(title);
    };

  const maxSoldTotal = soldTotal > 20 ? 20 : soldTotal;
  const maxUnSoldTotal = unsoldTotal > 20 ? 20 : unsoldTotal;



 

  console.log(products)

  const handlePageChange = (value) => {
    // Update the current page state
    setPage(value);

    // Send a Google Analytics event to track pagination
    ReactGA.event({
      category: "Pagination",
      action: "Page Changed",
      label: `Page ${value}`, // Label the event with the page number
      value: value // Send the page number as the value
    });
  };

  const handleSoldPageChange = (value) => {
    // Update the current page state
    setPageSold(value);

    // Send a Google Analytics event to track pagination
    ReactGA.event({
      category: "Pagination",
      action: "Page Changed",
      label: `Page ${value}`, // Label the event with the page number
      value: value // Send the page number as the value
    });
  };

  const handleContactClick = () => {

    history.push("/contact")
   
  
  };

  const handlePropertyClick = () => {

    history.push("/property-list")
  
  
  };
 
  return (
    <>
    <MetaData title="TST Real Estate" />
    <div className='home-page-model'>
      <div className='home-model'>
          <div className="home-remodel-text">
           
            <h1>Discover your dream property with TST Real Estate</h1>
            <button onClick={() => handlePropertyClick()}>Explore Properties <FaArrowRightLong /></button>
          </div>
      </div>
      <div className="new-arrival-model">
        
        <h2>New Listings</h2>
        <p>Check out these new properties on sale!</p>
        <div className="center">
          <div className="wrapperRe">
          {
                  products?.map(n => ( 
              <div className="cardRe">
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {
                          n?.images?.map(i => (
                            <SwiperSlide >
                              <img src={i?.Location} alt={n?.title} />
                            </SwiperSlide>
                          ))
                        }
                       
                      </Swiper>
               
                <div className="locationRe">
                  <h3>{n?.location}</h3>
                  <FaLocationDot className='locationIcon'/>
                </div>
                <div className="descRe">
                  <HomeDesc desc={n?.desc}/>
                
                </div>
                <hr />
                <div className="cardRe-buttons">
                  <button className='white-btn' onClick={() => handleNewarraivalClick(n?._id , n?.title)}>Show Details</button>
                  <button className='primary-btn' onClick={() => handleContactClick()}>
                    Book a Tour <FaArrowRightLong/>
                  </button>
                </div>
              </div>
                 ))
                }
              
          </div>

          <div className="map">
          <AntdPagination defaultCurrent={1} total={maxUnSoldTotal} pageSize={perPage} onChange={handlePageChange}/>
          </div>
        </div>
      </div>
      {
        productsSold?.length > 0 && (
          <div className="new-arrival-model">
            <h2>Success Sales</h2>
            <p>Thank you for trusting TST Real Estate!</p>
          <div className="center">
          <div className="wrapperRe">
          {
                  productsSold?.map(n => ( 
              <div className="cardRe sale">
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {
                          n?.images?.map(i => (
                            <SwiperSlide >
                              <img src={i?.Location} alt={n?.title} />
                            </SwiperSlide>
                          ))
                        }
                       
                      </Swiper>
               
                <div className="locationRe">
                  <h3>{n?.location}</h3>
                  <FaLocationDot className='locationIcon'/>
                </div>
                <div className="descRe">
                  <HomeDesc desc={n?.desc}/>
                
                </div>
                <hr />
                <div className="cardRe-buttons">
                  <button className='white-btn' onClick={() => handleNewarraivalClick(n?._id , n?.title)}>Show Details</button>
                  <button className='primary-btn' onClick={() => handleContactClick()}>
                    Book a Tour <FaArrowRightLong/>
                  </button>
                </div>
              </div>
                 ))
                }
              
          </div>
  
            <div className="map">
              <AntdPagination defaultCurrent={1} total={maxSoldTotal} pageSize={perPage} onChange={handleSoldPageChange}/>
            </div>
          </div>
        </div>
        )
      }
      <div className="homeRe-service">
          <div className="serviceRe-left">
            <h2 className="serviceRe-text">Our Services</h2>
            <p className="serviceRe-text">We offer a range of services for you. Check out our popular packages below.</p>

            <CAccordion style={customVars}>
              <CAccordionItem itemKey={1} >
                <CAccordionHeader >Ploting and House Design</CAccordionHeader>
                <CAccordionBody className='accordian-paragraph'>
                At TST Real Estate, we help with <strong>land plotting</strong> and <strong>house design</strong>, making sure your 
                <strong> property</strong> is ready for <strong>development</strong>. We work with you to divide your 
                <strong> land</strong> into plots or design a house that fits your needs and the <strong>property’s layout</strong>. 
                Our services follow <strong>local rules and regulations</strong>, ensuring everything is done properly. 
                Whether you’re <strong>building a new home</strong> or preparing <strong>land</strong> for future use, we focus on 
                <strong> practical designs</strong> and <strong>clear planning</strong>.

                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Land and Topological Survey</CAccordionHeader>
                <CAccordionBody className='accordian-paragraph'>
                  At TST Real Estate, we provide <strong>land and topological survey</strong> land and topological survey services to give you accurate details about your property.
                  Our surveys measure the <strong>land's features, boundaries</strong>, and <strong>elevation</strong>, helping you understand the <strong>terrain</strong> before 
                  building or development. We use <strong>reliable</strong> methods to ensure that all measurements are precise, so you can make 
                  informed decisions about your property. Whether it's for <strong>construction, land division</strong>, or <strong>planning</strong>, our surveys 
                  provide the essential information you need.
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>Construction Layout</CAccordionHeader>
                <CAccordionBody className='accordian-paragraph'>
                At TST Real Estate, we offer <strong>construction layout</strong> services to help you plan your <strong>
                building project</strong>. Our team will work with you to create clear plans that show the layout of your 
                <strong> structure</strong>, including <strong>room sizes</strong>, <strong>entrances</strong>, 
                and other important details. We ensure that our designs make the best use of your <strong>property </strong> 
                and comply with <strong>local building codes</strong>. Whether you are building a <strong>new home </strong> 
                or updating an <strong>existing one</strong>, our construction layout services will assist you in achieving your goals.

                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={4}>
                <CAccordionHeader>Civics and Law Consultant</CAccordionHeader>
                <CAccordionBody className='accordian-paragraph'>
                At TST Real Estate, we provide <strong>civics and law consulting</strong> services to guide you through legal 
                matters related to your <strong>property</strong>. Our experienced team will help you understand local 
                <strong> laws</strong> and <strong>regulations</strong> that affect your real estate transactions. 
                Whether you need assistance with <strong>property ownership</strong>, <strong>zoning laws</strong>, or 
                <strong> contract review</strong>, we offer practical advice to ensure compliance and protect your interests. 
                Our goal is to make the legal aspects of your real estate journey clear and manageable.

                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
          <div className="serviceRe-right">
              <img src={servicespic} alt="" />
          </div>
      </div>
     
      <div className="home-model-banner">
        <div className="banner-container">
          
          <div className="banner-content">
            <h2>Trust TST Real Estate - Your Source for Verified and Secure Property Investments</h2>
            <p>Discover your perfect home with TST Real Estate. We offer verified properties you can trust, ensuring a secure 
            investment for your future. TST Real Estate is not just a service; we are your trusted advisor and counselor, 
            providing reliable and flexible solutions tailored to your needs. Let us guide you through every step of the real 
            estate journey with confidence and peace of mind.</p>
          </div>
          <img  src={banner} alt="" />
        </div>
      </div>
    </div>
    </>
    
  )
}

export default Home