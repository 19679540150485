import React, { useCallback, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import property1 from '../../assests/property/1.png'
import property2 from '../../assests/property/2.png'
import property3 from '../../assests/property/3.png'
import property4 from '../../assests/property/4.png'
import property5 from '../../assests/property/5.jpg'
import { polyline } from '../../action/polyline';
import { useParams } from 'react-router-dom';
// import { allPolygons } from '../../action/polygon';
import property6 from '../../assests/property/6.png'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Modal, ModalGateway } from "react-images";
import Carousel from "react-images";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './singlemapdand.css'


const imgs = [
    property1,
    property2,
    property3,
    property4,
    property5,
    property6
]

const mark = `List:
React-Leaflet Integration: Implementing map functionalities using React-Leaflet.

Search Bar with Autocomplete: Adding a search bar with autocomplete for existing pins.

leaflet-measure Integration: Integrating measuring tools into maps.

Editable Polygons: Using react-leaflet-editable for polygon editing.

API Timing Issue: Troubleshooting API request timing within useEffect.

EmailJS Integration: Sending emails and handling UI feedback.

State Management: Managing state for select inputs and asynchronous data fetching.

Mapbox Integration: Transitioning from Leaflet to Mapbox while maintaining coordinate consistency.List:
React-Leaflet Integration: Implementing map functionalities using React-Leaflet.

Search Bar with Autocomplete: Adding a search bar with autocomplete for existing pins.

leaflet-measure Integration: Integrating measuring tools into maps.

Editable Polygons: Using react-leaflet-editable for polygon editing.

API Timing Issue: Troubleshooting API request timing within useEffect.

EmailJS Integration: Sending emails and handling UI feedback.

State Management: Managing state for select inputs and asynchronous data fetching.

Mapbox Integration: Transitioning from Leaflet to Mapbox while maintaining coordinate consistency.

Paragraph:

User is extensively working with map integrations in React applications, 

transitioning from React-Leaflet to Mapbox while ensuring consistency in coordinate formats. 

They have implemented features like a search bar with autocomplete for map pins and integrated measuring 

tools using leaflet-measure. For editing polygons, they prefer react-leaflet-editable. User is also tackling state 

management issues, such as setting default values and managing asynchronous data fetching. Additionally, they are using emailjs to 

send emails from their React application, aiming to provide immediate UI feedback upon successful email sending. Troubleshooting API 

timing issues within useEffect is another area they are focusing on to ensure smooth data retrieval in their application.






`

function SingleMapdand() {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const { slug } = useParams();
    const [details, setDetails] = useState(null)

    const openLightbox = useCallback((event, { image, index }) => {
 
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

      useEffect(() => {


        loadPolyline();
    
    }, []);
    
    
    
    const loadPolyline = async () => {
    try {
        const singlePolyline = await polyline(slug);
        setDetails(singlePolyline)
    
        
    
    } catch (error) {
        console.error('Error loading property:', error);
    }
    };

    console.log(details)
  return (
    <div className='singlemapdand-model'>
        <h1>{details?.title}</h1>
        <div className="single-m-main">
          {
            details && details?.images.length > 0 && (
              <div className="single-m-left">
              <div className="project-slider">
                  <Swiper pagination={true} modules={[Pagination]} className="mySwiper1">
                    {
                      details && details?.images.map((image, index) => (
                        <SwiperSlide key={index} style={{cursor:"pointer"}}>
                          <img src={image.Location} alt="" onClick={(e) => openLightbox(e, { image, index })} />
                        </SwiperSlide>
                      ))
                    }
                   
                  </Swiper>
              </div>
          </div>
            )
          }
           
            <div className="single-m-right">
            <Markdown className="single-m-details" remarkPlugins={[remarkGfm]}>{details?.desc}</Markdown>
            </div>
        </div>
        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={details?.images?.map(image => ({
                src: image.Location || '', // Ensure src property is set
                srcset: '',
                caption: '',
                regular: '',
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default SingleMapdand