import React, { useCallback, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import { polygon } from '../../action/polygon';
import { useParams } from 'react-router-dom';
// import { allPolygons } from '../../action/polygon';
import property6 from '../../assests/property/6.png'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Modal, ModalGateway } from "react-images";
import Carousel from "react-images";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';




function SinglePlotMapdand() {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const { slug } = useParams();
    const [details, setDetails] = useState(null)

    const openLightbox = useCallback((event, { image, index }) => {
 
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

      useEffect(() => {


        loadPolyline();
    
    }, []);
    
    
    
    const loadPolyline = async () => {
    try {
        const singlePolygon = await polygon(slug);
        setDetails(singlePolygon)
    
        
    
    } catch (error) {
        console.error('Error loading property:', error);
    }
    };

    console.log(details)
  return (
    <div className='singlemapdand-model'>
        <h1>{details?.title}</h1>
        <div className="single-m-main">
          {
            details && details?.images?.length > 0 && (
              <div className="single-m-left">

              <div className="project-slider">
                  <Swiper pagination={true} modules={[Pagination]} className="mySwiper1">
                    {
                      details && details?.images.map((image, index) => (
                        <SwiperSlide key={index} style={{cursor:"pointer"}}>
                          <img src={image.Location} alt="" onClick={(e) => openLightbox(e, { image, index })} />
                        </SwiperSlide>
                      ))
                    }
                   
                  </Swiper>
              </div>
          </div>
            )
          }
           
            <div className="single-m-right">
            <Markdown className="single-m-details" remarkPlugins={[remarkGfm]}>{details?.desc}</Markdown>
            </div>
        </div>
        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={details?.images?.map(image => ({
                src: image.Location || '', // Ensure src property is set
                srcset: '',
                caption: '',
                regular: '',
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default SinglePlotMapdand