export const menu = [{
    id: 1,
    title: "Property",
    listItems: [{
            id: 1,
            title: "Upload",
            url: "/dashboard",
            icon: "home.svg",
        },
        {
            id: 2,
            title: "Update",
            url: "/dashboard/property-update",
            icon: "home.svg",
        },

    ],
},
{
    id: 2,
    title: "जग्गा मापदण्ड",
    listItems: [
        {
            id: 1,
            title: "Upload",
            url: "/dashboard/mapdand-upload",
            icon: "user.svg",
        },
        {
            id: 2,
            title: "Update",
            url: "/dashboard/mapdand-update",
            icon: "user.svg",
        },
       
        
    ],
},

];

