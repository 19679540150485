import React, { useEffect, useState , useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { FaLocationDot , FaArrowRightLong } from "react-icons/fa6";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {blogcontent} from './blogdata'
import { Modal, ModalGateway } from "react-images";
import './blogdisplay.css'
import HomeDesc from '../Home/HomeDesc';
import Gallery from "react-photo-gallery";
import Carousel from "react-images";
import { otherProperty, property } from '../../action/property';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';

import 'swiper/css/pagination';



function BlogsDisplay({history}) {
    const { slug } = useParams();
    const [otherProperties, setOtherProperties] = useState([])
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {


    
      loadOtherProperty()
  }, [slug]);

  const handleNewarraivalClick = (title) => {

    history.push(`/property/${title}`)
    
  
  };

  const handleContactClick = () => {

    history.push("/contact")
   
  
  };


    const formatString = (str) =>
        str
          .toLowerCase()
          .replace(/ /g, "-") // Replace spaces with hyphens
          .replace(/[^\w-]+/g, ""); // Remove special characters except hyphens
      
      // Find the blog post that matches the slug
      const blogPost = blogcontent.find(
        (post) => formatString(post.title) === formatString(slug)
      );

      const loadOtherProperty = async () => {
        const other = await otherProperty("66b469674ef15fb149a83529");
        setOtherProperties(other)
      }
      
      const openLightbox = useCallback((event) => {
        setCurrentImage(0); // Set to the first image (index 0)
        setViewerIsOpen(true);
      }, []);
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

      const images = [
        {
          Location: blogPost.img, // Use the image URL from blogPost
        },
      ];
      
  
  return (
    <div className='blogs-display-container'>
        <h1>{blogPost.title}</h1>
        <div className="blogs-display-main">
          <div className="blogs-display-left">
              <img src={blogPost.img} alt=""  onClick={openLightbox}  />
              <p className="blog-display-text">{blogPost.desc}</p>
          </div>
          <div className="blogs-display-right">
          <h3>Property Feeds</h3>
            {
                  otherProperties?.map(n => ( 
              <div className="cardRe property">
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {
                          n?.images?.map(i => (
                            <SwiperSlide >
                              <img src={i?.Location} alt={n?.title} />
                            </SwiperSlide>
                          ))
                        }
                       
                      </Swiper>
               
                <div className="locationRe">
                  <h3>{n?.location}</h3>
                  <FaLocationDot className='locationIcon'/>
                </div>
                <div className="descRe">
                  <HomeDesc desc={n?.desc}/>
                
                </div>
                <hr />
                <div className="cardRe-buttons">
                  <button className='white-btn' onClick={() => handleNewarraivalClick(n?._id , n?.title)}>Show Details</button>
                  <button className='primary-btn'  onClick={() => handleContactClick()}>
                    Book a Tour <FaArrowRightLong/>
                  </button>
                </div>
              </div>
                 ))
                }
          </div>
        </div>
        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map(image => ({
                src: image?.Location || '', // Ensure src property is set
                srcset: '',
                caption: '',
                regular: '',
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default BlogsDisplay