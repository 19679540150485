import React, { useEffect, useState } from 'react'
import MetaData from '../../MetaData'
import Menu from '../Menu/Menu'
import Navbar from '../Navbar/Navbar'
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';
import './mapdandupload.css'
import { Icon, divIcon, point, polyline } from "leaflet";
import { MdDeleteForever } from "react-icons/md";
import {  toast } from 'react-toastify';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup , useMap,Polyline,Circle ,CircleMarker , Polygon , withLeaflet} from "react-leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import L from "leaflet";
import Description from './Description.jsx'
import { Button, Input, Modal } from 'antd';
import MapClickHandler from '../Mapdand/MapClickHandler';
import ImageModel from '../ModelImage/ImageModel';
import TextArea from 'antd/es/input/TextArea';
import { allPolylines, deletePolyline, uploadPolyline } from '../../action/polyline';
import { allPolygons, deletePolygon, uploadPolygon } from '../../action/polygon';

const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/2238/2238263.png",
//   iconUrl: require("./icons/placeholder.png"),
  iconSize: [27, 27 , true] ,// size of the icon
 
});

const PinIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/535/535137.png",
//   iconUrl: require("./icons/placeholder.png"),
  iconSize: [27, 27 , true] ,// size of the icon
 
});

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
});

// Markers

const UseGeoSearch = () => {
  const map = useMap();
  
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar', // optional: default 'button', can be 'bar' for a full-width search bar
      showMarker: true, // optional: true|false  - default true
      showPopup: false, // optional: true|false  - default false
      marker: {
        icon: new L.Icon.Default(),
        draggable: false,
      },
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, [map]);
  
  return null;
};


function MapdandUpload({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [polylineMarkerPoint, setPolylineMarkerPoint] = useState(null)
  const [polylineClicked, setPolylineClicked] = useState(false)
  const [polygonClicked, setPolygonClicked] = useState(false)
  const [polylineMarkerClicked, setPolylineMarkerClicked] = useState(false)
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [loading, setLoading] = useState(false)
  const [polylineImages, setPolylineImages] = useState([])
  const [polylineTitle, setPolylineTitle] = useState("")
  const [photoVisible, setPhotoVisible] = useState(false);
  const [photoPolygonVisible, setPhotoPolygonVisible] = useState(false);
  const [polylineDescVisible, setPolylineDescVisible] = useState(false)
  const [polygonDescVisible, setPolygonDescVisible] = useState(false)
  const [polylineDesc, setPolylineDesc] = useState("")
  const [polygonDesc, setPolygonDesc] = useState("")
  const [polylineWidth, setPolylineWidth] = useState(2)
  const [linewidth, setLinewidth] = useState(0)
  const [polylineColor, setPolylineColor] = useState("blue")
  const [inputValue, setInputValue] = useState("");
  const [polygonTitle, setPolygonTitle] = useState("")
  const [polygonImages, setPolygonImages] = useState([])
  const [polygonColor, setPolygonColor] = useState("green")
  const [polylines, setPolylines] = useState(null)
  const [polygons, setPolygons] = useState(null)
  const [zoomPolyline, setZoomPolyline] = useState(2)
  const [zoomPolygon, setZoomPolygon] = useState(2)


  useEffect(() => {
    
    loadAllPolyline()
  
}, [])

console.log("polylinedd",polylineCoordinates)

const loadAllPolyline = async() =>{

  try {
    const polylineLoad = await allPolylines()
    const polygonLoad = await allPolygons()


    setPolylines(polylineLoad);
    setPolygons(polygonLoad)
  
  } catch (error) {
    
  }

}

  console.log(polygons)


  const handlePolylineButtonClick = () => {
    setPolylineClicked(!polylineClicked);
    setPolygonClicked(false);
    setPolylineMarkerClicked(false)
  };

  const handlePolygonButtonClick = () => {
    setPolygonClicked(!polygonClicked);
    setPolylineClicked(false);
    setPolylineMarkerClicked(false)
  };

  const handlePolylineMarkerClicked = () => {
    setPolylineMarkerClicked(!polylineMarkerClicked);
    setPolylineClicked(false);
    setPolygonClicked(false)
  };


  function calculateCentroid(polygonCoordinates) {
    let x = 0;
    let y = 0;
    const length = polygonCoordinates?.length;
  
    polygonCoordinates?.forEach(([lat, lng]) => {
      x += lat;
      y += lng;
    });
  
    return [x / length, y / length];
  }

  const centroid = calculateCentroid(polygonCoordinates);

  useEffect(() => {
      calculateCentroid()
  }, [polygonCoordinates])
  

 
  const addPolylineCoordinate = (coordinate) => {
    setPolylineCoordinates((prevCoords) => [...prevCoords, coordinate]);
  };

  const addPolygonCoordinate = (coordinate) => {
    setPolygonCoordinates((prevCoords) => [...prevCoords, coordinate]);
  };

  const handleLinePressEnter = () => {
    setPolylineWidth(linewidth);
    setLinewidth(0)
  };

  const handlePressEnter = () => {
    setPolylineColor(inputValue);
    setInputValue("")
  };
  const handlePressPolygonEnter = () => {
    setPolygonColor(inputValue);
    setInputValue("")
  };

  console.log("polyline" , polylineCoordinates)
  console.log("polygon" , polygonCoordinates)
  useEffect(() => {
    const isAuthInvalid = !auth 
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/tst-admin');
        return;
      }
    } else {
      history.push('/tst-admin');
    }
  }, [auth]);

  const handleClearPolyline = () => {
    if (window.confirm('Are you sure you want to clear the polygon coordinates?')) {
      setPolylineCoordinates([]);
    }
  };

  const handleClearPolygon = () => {
    if (window.confirm('Are you sure you want to clear the polygon coordinates?')) {
      setPolygonCoordinates([]);
    }
  };



  const handlePolylineSubmit = async(e) => {
    e.preventDefault();
    
    if (
      polylineTitle.trim() === "" ||
    
   
      polylineDesc.trim() === "" ||
      zoomPolyline === 0 ||
      polylineCoordinates.length === 0
    ) {
      alert("Please fill in all the fields of Polyline.");
      return;
    }
    const polylineData = {
      title: polylineTitle,
      images: polylineImages,  // Make sure images are in the right format (e.g., URLs or base64)
      width : polylineWidth ,
      zoom: zoomPolyline,
      marker: polylineMarkerPoint,
      desc: polylineDesc,
      coordinate: polylineCoordinates,
      color: polylineColor,  // Assuming this is the color you set
      
    };
    try {
        
        const response = await uploadPolyline(polylineData, auth?.token);
        if (response) {
          toast.success('Polyline uploaded successfully!');
          // Clear all form fields
          setPolylineTitle('');
          setPolylineImages([]);
          setPolylineWidth(3);
          setPolylineMarkerPoint([]);
          setPolylineDesc('');
          setPolylineCoordinates([]);
          setPolylineColor('yellow');
          setPolygonClicked(false);
          setPolylineClicked(false);
          setPolylineMarkerClicked(false)
          loadAllPolyline()
        }
    } catch (error) {
      toast.error('Failed to upload Polyline. Please try again.');
    }
  };

  const handlePolygonSubmit = async(e) => {
    e.preventDefault();
    
    if (
      polygonTitle.trim() === "" ||
      
      centroid.length === 0 ||
      polygonDesc.trim() === "" ||
      zoomPolygon === 0 ||
      polygonCoordinates.length === 0
    ) {
      alert("Please fill in all the fields of Polygon.");
      return;
    }
    const polygonData = {
      title: polygonTitle,
      images: polygonImages,  // Make sure images are in the right format (e.g., URLs or base64)
      zoom: zoomPolygon,
      marker: centroid,
      desc: polygonDesc,
      coordinate: polygonCoordinates,
      color: polygonColor,  // Assuming this is the color you set
      
    };
    try {
        
        const response = await uploadPolygon(polygonData, auth?.token);
        if (response) {
          toast.success('Polygon uploaded successfully!');
          // Clear all form fields
          setPolygonTitle('');
          setPolygonImages([]);
          setPolygonDesc('');
          setPolygonCoordinates([]);
          setPolygonColor('blue');
          setPolygonClicked(false);
          setPolylineClicked(false);
          setPolylineMarkerClicked(false)
          loadAllPolyline()
         
        }
    } catch (error) {
      toast.error('Failed to upload Polygon. Please try again.');
    }
  };
  
  const handlePolylineDelete = async(id , title) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${title}`);

    if (isConfirmed) {
      try {
        const response = await deletePolyline(id, auth?.token);

        console.log(response);
        
        if (response) {
          toast.success('polyline Deleted successfully!');
          loadAllPolyline()
        } else {
          toast.error('Failed to delete the polyline.');
        }
      } catch (error) {
        toast.error('Failed to delete the polyline.');
      }
    }
  }

  const handlePolygonDelete = async(id , title) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${title}`);

    if (isConfirmed) {
      try {
        const response = await deletePolygon(id, auth?.token);

        console.log(response);
        
        if (response) {
          toast.success('polyline Deleted successfully!');
          loadAllPolyline()
        } else {
          toast.error('Failed to delete the polyline.');
        }
      } catch (error) {
        toast.error('Failed to delete the polyline.');
      }
    }
  }

  const handleUpdateClick = (id) =>{
    history.push(`/dashboard/mapdand-update/${id}`)
  }

  const handlePolygonUpdateClick = (id) =>{
    history.push(`/dashboard/mapdand-update/polygon/${id}`)
  }

  const handleZoomInputChange = (e) => {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);
  
    if (numericValue >= 1 && numericValue <= 20) {
      setZoomPolyline(numericValue);
    }
  };

  const handleZoomPolygonInputChange = (e) => {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);
  
    if (numericValue >= 1 && numericValue <= 20) {
      setZoomPolygon(numericValue);
    }
  };

  const clearLastPoint = () => {
    if (polylineCoordinates.length > 0) {
      setPolylineCoordinates(polylineCoordinates.slice(0, -1));
    }
  };

   const clearLastPolygon = () => {
    if (polygonCoordinates.length > 0) {
      setPolygonCoordinates(polygonCoordinates.slice(0, -1));
    }
  };
  
  return (
    <>
    <MetaData title="Dashboard" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items">
            <h2>जग्गा मापदण्ड</h2>
            <div className="mapdandUpdate-items">
              <div className="mapdandUd-left">
                <div className="polyline-update">
                  <h3>Polyline</h3>
                  <div className="mapdandUd-polyline-details">
                  <Button className='mapdandUd-btn' onClick={handlePolylineButtonClick}>
                      {polylineClicked ? 'Deactivate Polyline' : 'Activate Polyline'}
                  </Button>
                  <Button className='mapdandUd-btn' onClick={handleClearPolyline}>
                  Clear Polyline
                  </Button>
                  </div>
                  <Button onClick={clearLastPoint}>Clear Point</Button>
                 
                  <div className="mapdandUd-polyline-details">
                  <Input className='mapdandUd-btn' value={linewidth}
                  onChange={(e) => setLinewidth(e.target.value)}
                  onPressEnter={handleLinePressEnter}
                  placeholder='Width'/>
                  
                  <Input className='mapdandUd-btn' value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handlePressEnter}
                  placeholder='Color' />
                  </div>
                  <Input type="number" name="" max={20} min={1} value={zoomPolyline}   onChange={handleZoomInputChange}
                  className='form-input' placeholder='Zoom Level' id="" />
                  <Input type="text" name="" value={polylineTitle} onChange={(e) => setPolylineTitle(e.target.value)}
                  className='form-input' placeholder='Title' id="" />
                  <div className="mapdandUd-polyline-details">
                  <Button className='mapdandUd-btn' onClick={() => setPolylineDescVisible(true)} >
                    Polyline Details
                  </Button>
                  <Button className='mapdandUd-btn' onClick={() => setPhotoVisible(true)}>
                    Upload Photo
                  </Button>
                  </div>
                  <Button className='form-submit' onClick={handlePolylineSubmit}>Submit</Button>
                </div>


                <div className="polyline-update">
                  <h3>Polygon</h3>
                  <div className="mapdandUd-polyline-details">
                    <Button className='mapdandUd-btn' onClick={handlePolygonButtonClick}>
                        {polygonClicked ? 'Deactivate Polygon' : 'Activate Polygon'}
                    </Button>
                    <Button className='mapdandUd-btn' onClick={handleClearPolygon}>
                    Clear Polygon
                    </Button>
                  </div>
                  <Button onClick={clearLastPolygon}>Clear Point</Button>
                  <Input type="text" name="" value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handlePressPolygonEnter}
                  className='form-input' placeholder='Color' id="" />
                  <Input type="number" name="" max={20} min={1} value={zoomPolygon}   onChange={handleZoomPolygonInputChange}
                  className='form-input' placeholder='Zoom Level' id="" />
                  <Input type="text" name="" value={polygonTitle} onChange={(e) => setPolygonTitle(e.target.value)}
                  className='form-input' placeholder='Title' id="" />
                 
                  <div className="mapdandUd-polyline-details">
                    <Button className='mapdandUd-btn' onClick={() => setPolygonDescVisible(true)}>
                      Polyline Details
                    </Button>
                    <Button className='mapdandUd-btn' onClick={() => setPhotoPolygonVisible(true)}>
                      Upload Photo
                    </Button>
                  </div>
                  <Button className='form-submit' onClick={handlePolygonSubmit}>Submit</Button>
                </div>
              </div>
              <div className="mapdandUd-right">
                <MapContainer 
                  center={[27.64523010276538, 85.28659710064125]} 
                   
                  editable={true}
                  zoom={10} minZoom={2} 
                  style={{ width: '100%', height: '100vh' }}
                  // className='dashboard-map'
                >
                    <TileLayer
                      url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                      maxZoom={22}
                      subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                      attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
                    />
                    <UseGeoSearch />
                    {polylineCoordinates.length > 0 && (
                      <Polyline
                        positions={polylineCoordinates}
                        pathOptions={{ color: polylineColor, weight: polylineWidth }}
                     
                      >
                {
                
              
                                <Popup>
                                  <h3>{polylineTitle}</h3>
                                  <Markdown className="maapdand-details" remarkPlugins={[remarkGfm]}>{polylineDesc}</Markdown>
                                </Popup>
                          
                
                }
                
                  
                      </Polyline>
                      
                    )}

            

                    {polygonCoordinates.length > 0 && (
                              <Polygon
                                positions={polygonCoordinates}
                                pathOptions={{ color: polygonColor, weight: 3 }}
                                
                              >
                                  <Marker position={centroid} icon={customIcon}>
                                <Popup>
                                  <h3>{polygonTitle}</h3>
                                  <Markdown className="maapdand-details" remarkPlugins={[remarkGfm]}>{polygonDesc}</Markdown>
                                </Popup>
                              </Marker>
                              </Polygon>
                              
                    )}

                    {
                      polylines && polylines.map((pl) => (
                        <Polyline
                        key={pl?._id}
                        positions={pl?.coordinate}
                        pathOptions={{ color: pl?.color, weight: pl?.width || 3 }}
                     
                      >
                        {
                      
                          
                                        <Popup>
                                        <div className='marker-div'>
                                          <h3 onClick={() =>handleUpdateClick(pl._id)}>{pl?.title}</h3>
                                          <MdDeleteForever className='mapdand-delete' onClick={() =>handlePolylineDelete(pl?._id , pl?.title)}/>
                                        </div>
                                          <Description desc={pl?.desc} />
                                        </Popup>
                                
                      
                        }
                        
                  
                      </Polyline>
                      
                      ))
                    }

                      {  polygons && polygons.map((pg) => 
                              <Polygon
                              key={pg?._id}
                                positions={pg?.coordinate}
                                pathOptions={{ color: pg?.color, weight: 3 }}
                                
                              >
                                  <Marker position={pg?.marker} icon={customIcon}>
                                <Popup >
                                  <div className='marker-div'>
                                    <h3 onClick={() =>handlePolygonUpdateClick(pg._id)}>{pg?.title}</h3>
                                    <MdDeleteForever className='mapdand-delete' onClick={() =>handlePolygonDelete(pg?._id , pg?.title)}/>
                                  </div>
                                  
                                  <Description desc={pg?.desc} />
                                </Popup>
                              </Marker>
                              </Polygon>
                              
                    )}
                    {polylineClicked && (
                        <MapClickHandler setClickedCoordinates={setClickedCoordinates} addPolylineCoordinate={addPolylineCoordinate}/>
                    )}
                    {polygonClicked && (
                        <MapClickHandler setClickedCoordinates={setClickedCoordinates} addPolylineCoordinate={addPolygonCoordinate}/>
                    )}
                 
                </MapContainer>
              </div>
            </div>
           
          </div>
        </div>
    </div>

    <ImageModel 
      photoVisible={photoVisible} 
      setPhotoVisible={setPhotoVisible} 
      images={polylineImages} 
      setImages={setPolylineImages} 
      loading={loading}
      setLoading={setLoading}
      slug={polylineTitle}/>

      <ImageModel 
      photoVisible={photoPolygonVisible} 
      setPhotoVisible={setPhotoPolygonVisible} 
      images={polygonImages} 
      setImages={setPolygonImages} 
      loading={loading}
      setLoading={setLoading}
      slug={polygonTitle}/>

          <Modal
              title="Description"
              centered
              open={polylineDescVisible}
              onCancel={() => setPolylineDescVisible(false)}
              footer={null}
            >
                <TextArea className='mandand-description' cols={10} rows={10} value={polylineDesc}
                onChange={(e) => setPolylineDesc(e.target.value)}
                placeholder='Enter Polyline Description'/>
            </Modal>

            <Modal
              title="Description"
              centered
              open={polygonDescVisible}
              onCancel={() => setPolygonDescVisible(false)}
              footer={null}
            >
                <TextArea className='mandand-description' cols={10} rows={10} value={polygonDesc}
                onChange={(e) => setPolygonDesc(e.target.value)}
                placeholder='Enter Polygon Description'/>
            </Modal>
    </>
    
  )
}

export default MapdandUpload