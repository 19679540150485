import React, { useState, useEffect } from 'react';
import MetaData from "../../MetaData";
import ReverseCalc from './ReverseConverter';
import { FaArrowRightLong } from "react-icons/fa6";
import './areaconverter.css';
import { Button } from 'antd';

function AreaConverter() {
  const [ropani, setRopani] = useState('');
  const [aana, setAana] = useState('');
  const [paisa, setPaisa] = useState('');
  const [daam, setDaam] = useState('');
  const [sqFeet, setSqFeet] = useState('');
  const [sqMeter, setSqMeter] = useState('');

  const handleRopaniChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'ropani':
        setRopani(value);
        break;
      case 'aana':
        setAana(value);
        break;
      case 'paisa':
        setPaisa(value);
        break;
      case 'daam':
        setDaam(value);
        break;
      default:
        break;
    }
    setSqFeet('');
    setSqMeter('');
  };

  const handleSqFeetChange = (event) => {
    const value = event.target.value;
    setSqFeet(value);
    setRopani('');
    setAana('');
    setPaisa('');
    setDaam('');
  };

  const handleSqMeterChange = (event) => {
    const value = event.target.value;
    setSqMeter(value);
    setRopani('');
    setAana('');
    setPaisa('');
    setDaam('');
  };

  const calculateArea = () => {
    if (ropani || aana || paisa || daam) {
      let area = 0;
      let area1 = 0;
      if (ropani) {
        area += parseFloat(ropani) * 5476.0000;
        area1 += parseFloat(ropani) * 508.73704704;
      }
      if (aana) {
        area += parseFloat(aana) * 342.2500;
        area1 += parseFloat(aana) * 31.79606544;
      }
      if (paisa) {
        area += parseFloat(paisa) * 85.5625;
        area1 += parseFloat(paisa) * 7.94901636;
      }
      if (daam) {
        area += parseFloat(daam) * 21.390625;
        area1 += parseFloat(daam) * 1.98725409;
      }

      setSqFeet(Math.round(area));
      setSqMeter(Math.round(area1));
    } else if (sqFeet) {
      calculateUnitsFromSqFeet(sqFeet);
    } else if (sqMeter) {
      calculateUnitsFromSqMeter(sqMeter);
    }
  };

  const calculateUnitsFromSqFeet = (sqFeet) => {
    let totalArea = parseFloat(sqFeet);

    const ropaniValue = Math.floor(totalArea / 5476.0000);
    totalArea -= ropaniValue * 5476.0000;
    const aanaValue = Math.floor(totalArea / 342.2500);
    totalArea -= aanaValue * 342.2500;
    const paisaValue = Math.floor(totalArea / 85.5625);
    totalArea -= paisaValue * 85.5625;
    const daamValue = totalArea / 21.390625;

    setRopani(ropaniValue.toString());
    setAana(aanaValue.toString());
    setPaisa(paisaValue.toString());
    setDaam(Math.round(daamValue).toString());
  };

  const calculateUnitsFromSqMeter = (sqMeter) => {
    let totalArea = parseFloat(sqMeter);

    const ropaniValue = Math.floor(totalArea / 508.73704704);
    totalArea -= ropaniValue * 508.73704704;
    const aanaValue = Math.floor(totalArea / 31.79606544);
    totalArea -= aanaValue * 31.79606544;
    const paisaValue = Math.floor(totalArea / 7.94901636);
    totalArea -= paisaValue * 7.94901636;
    const daamValue = totalArea / 1.98725409;

    setRopani(ropaniValue.toString());
    setAana(aanaValue.toString());
    setPaisa(paisaValue.toString());
    setDaam(Math.round(daamValue).toString());
  };

  const clearAll = () => {
    setRopani('');
    setAana('');
    setPaisa('');
    setDaam('');
    setSqFeet('');
    setSqMeter('');
  };

  return (
    <>
      <MetaData title="Area Converter" />
      <div className="area-converter-model">
        <h1>Area Converter</h1>
        <div className='Converter-title'>
          <h3>Ropani System <FaArrowRightLong /> Sq Feet / Sq Meter </h3>
          <h3>Sq Feet / Sq Meter  <FaArrowRightLong /> Ropani System </h3>
        </div>
        
        <div className="input-section">
          <div className="input-group">
            <h2>Ropani System</h2>
            <label >Ropani</label>
            <input type="number"  name="ropani" placeholder="Ropani" value={ropani} onChange={handleRopaniChange} disabled={sqFeet || sqMeter} />
            <label >Aana</label>
            <input type="number"  name="aana" placeholder="Aana" value={aana} onChange={handleRopaniChange} disabled={sqFeet || sqMeter} />
            <label >Paisa</label>
            <input type="number"  name="paisa" placeholder="Paisa" value={paisa} onChange={handleRopaniChange} disabled={sqFeet || sqMeter} />
            <label >Daam</label>
            <input type="number"  name="daam" placeholder="Daam" value={daam} onChange={handleRopaniChange} disabled={sqFeet || sqMeter} />
          </div>
        </div>
        <div className="result-section">
          <div className="result-group">
            <h2>Square Feet</h2>
            <input type="number" className="form-control" name="landinfo_sqfeet" placeholder="Sq.Feet" value={sqFeet} onChange={handleSqFeetChange} disabled={ropani || aana || paisa || daam} />
          </div>
          <div className="result-group">
            <h2>Square Meter</h2>
            <input type="number" className="form-control" name="landinfo_sqmeter" placeholder="Sq.Meter" value={sqMeter} onChange={handleSqMeterChange} disabled={ropani || aana || paisa || daam} />
          </div>
        </div>
        <div className="button-group">
          <button className="calc-button" onClick={calculateArea}>Calculate</button>
          <button className="clear-button" onClick={clearAll}>Clear All</button>
        </div>
      </div>
      {/* <ReverseCalc/> */}
    </>
  );
}

export default AreaConverter;
