import axios from 'axios';

export const uploadPolygon = async (polygonData , token) => {

    try {
        
        const response = await axios.post(
            '/api/upload-polygon',
            polygonData, // Provide an object with the data you want to send in the request body
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
       
        return response.data;
    } catch (error) {
       
        console.error(error);
        
    }
};

export const allPolygons= async () => {
  try {
      const response = await axios.get('/api/all-polygon');

     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};

export const deletePolygon = async(id, token) => {


  try {
      const response = await axios.delete(`/api/delete-polygon/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      return response.data;
  } catch (error) {
      return error.response.data;
  }

}


export const polygon= async (slug) => {
  try {
      const response = await axios.get(`/api/polygon/${slug}`);
      
     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};

export const updatePolygon = async (slug , polylineData , token) => {

  try {
      
      const response = await axios.put(
          `/api/update-polygon/${slug}`,
          polylineData, // Provide an object with the data you want to send in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
     
      return response.data;
  } catch (error) {
     
      console.error(error);
      
  }
};