import React from 'react'
import logo from '../../assests/TST logo.svg';
import { FaFacebook , FaYoutube   } from "react-icons/fa";
import {useHistory} from 'react-router-dom'
import { TbBrandTiktokFilled } from "react-icons/tb";
import { PiInstagramLogoFill } from "react-icons/pi";
import "./footer.css"

function Footer() {
  const history = useHistory()
  const handleLogoClick = () => {

    history.push("/")
    
  
  };

  const handleAboutClick = () => {

    history.push("/about-us")
 
  
  };

  const handlePropertyClick = () => {

    history.push("/property-list")
  
  
  };

  const handleEMIClick = () => {

    history.push("/emi-calculator")
  
  
  };

  const handleAREAClick = () => {

    history.push("/area-converter")
   
  
  };

  const handleMapdandClick = () => {

    history.push("/tstmap")
  
  
  };


  const handleBlogsClick = () => {

    history.push("/tstblogs")
    
  
  };
  



  return (
    <>
      <footer class="footer">
      <div className="footerRe-logo">
          <img src={logo} alt="" />
          <p>
          Contact us at 9761655210 / 9706096060 for a free consultation! Let us help you find or create the perfect property.</p>
         
      </div>
      <div className="footerRe-company">
          <h3>Main</h3>
          <h4 onClick={handleLogoClick}>Home</h4>
          <h4  onClick={handlePropertyClick}>Properties</h4>
          <h4 onClick={handleBlogsClick}>Blogs</h4>
          <h4 onClick={handleAboutClick}>About</h4>
      </div>
      <div className="footerRe-explore">
          <h3>Explore</h3>
          <h4 onClick={() =>{handleMapdandClick()}}>जग्गा मापदण्ड</h4>
          <h4  onClick={() =>{handleAREAClick()}}>Area Converter</h4>
          <h4  onClick={() =>{handleEMIClick()}}>EMI Calculator</h4>
         
      </div>

      <div className="footerRe-social">
        <h3>Socials</h3>
          <div className="footerRe-icons">
            <a  href="https://www.facebook.com/profile.php?id=61554883197497" target='_blank'><FaFacebook href="https://www.facebook.com/profile.php?id=61554883197497" className='footerRe-i'/> </a>
            <a href="https://www.instagram.com/tst_real_estate/" target='_blank'><PiInstagramLogoFill className='footerRe-i'/></a>
            <FaYoutube className='footerRe-i'/>
            <TbBrandTiktokFilled className='footerRe-i'/>
          </div>
      </div>
		</footer>
    <div className="bottom-footerRe">
        <h3>© 2024 <span>TST Real Estate</span>. All Right Reserved</h3>
        <h3>Developed By <span>Jeen Maharjan</span></h3>
    </div>
    </>
    
  )
}

export default Footer