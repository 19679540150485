import { BrowserRouter as Router, Route, Switch , useLocation  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from './component/ScrollToTop//ScrollToTop.jsx';
import Home from "./component/Home/Home.jsx";
import Footer from "./component/Footer/Footer.jsx"
import Headers from "./component/Header/Header.jsx"
import HeaderModel from "./component/Header/HeaderModel.jsx"
import PropertyDisplay from "./component/PropertyDisplay/PropertyDisplay.jsx"
import PageNotfound from "./component/PageNotfound/PageNotfound.jsx"
import AboutUs from "./component/AboutUs/AboutUs.jsx"
import PropertyList from "./component/PropertyList/PropertyList.jsx"
import EMICalculator from "./component/EMICalculator/EMICalculator.jsx"
import AreaConverter from "./component/AreaConverter/AreaConverter.jsx"
import Mapdand from "./component/Mapdand/Mapdand.jsx"
import Canvas from "./component/Canvas/Canvas.jsx"
import Demo from "./component/Demo/Demo.jsx"
import MapdandArea from "./component/Mapdand/MapdandArea.jsx"
import ContactPage from "./component/ContactPage/ContactPage.jsx"
import Dashboard from "./component/Dashboard/Dashboard.jsx";
import PropertyUpdate from "./component/PropertyUpdate/PropertyUpdate.jsx";
import PropertySingleUpdate from "./component/PropertySingleUpdate/PropertySingleUpdate.jsx";
import MapdandUpdate from "./component/MapdandUpdate/MapdandUpdate.jsx";
import MapdandSingleUpdate from "./component/MapdandSingleUpdate/MapdandSingleUpdate.jsx";
import MapdandSinglePolyhonUpdate from "./component/MapdandSinglePolyhonUpdate/MapdandSinglePolyhonUpdate.jsx";
import MapdandUpload from "./component/MapdandUpload/MapdandUpload.jsx";
import ChangePassword from "./component/ChangePassword/ChangePassword.jsx";
import Blogs from "./component/Blogs/Blogs.jsx";
import BlogsDisplay from "./component/Blogs/BlogsDisplay.jsx";
import Admin from "./component/Admin/Admin.jsx";
import SendMessage from "./component/SendMessage/SendMessage.jsx";
import SingleMapdand from "./component/SingleMapdand/SingleMapdand.jsx";
import SinglePlotMapdand from "./component/SinglePlotMapdand/SinglePlotMapdand.jsx";
import ReactGA from "react-ga4";


import { ReactLenis } from '@studio-freight/react-lenis';
const lenisOptions = {
  lerp: 0.07, // Increase this value for smoother and slower scrolling
  smooth: true,
  direction: 'vertical'
};

const TRACKING_ID = "G-WNEESH2XDQ"

function Layout() {
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    // Send a pageview hit to Google Analytics when the location changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  const showHeaderAndFooter = !(
   
    location.pathname === "/changepassword" ||
    location.pathname === "/admin" ||
    location.pathname === "/dashboard" ||
    location.pathname === "/dashboard/viewproject" ||
    location.pathname.startsWith("/dashboard/")
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (
    <>
        {showHeaderAndFooter && (
        <>
          {windowWidth <= 768 ? <Headers /> : <HeaderModel />}
        </>
      )}
       
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/tstblogs" component={Blogs} />
        <Route exact path="/tstblogs/:slug" component={BlogsDisplay} />
        <Route exact path="/property/:slug" component={PropertyDisplay} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/property-list" component={PropertyList} />
        <Route exact path="/emi-calculator" component={EMICalculator} />
        <Route exact path="/tstmap" component={Mapdand} />
        {/* <Route exact path="/demo" component={Demo} /> */}
        <Route exact path="/area-converter" component={AreaConverter} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/mapdand/line/:slug" component={SingleMapdand} />
        <Route exact path="/mapdand/Plot/:slug" component={SinglePlotMapdand} />
        <Route exact path="/dashboard/property-update" component={PropertyUpdate} />
        <Route exact path="/dashboard/property-update/:slug" component={PropertySingleUpdate} />
        <Route exact path="/dashboard/mapdand-upload" component={MapdandUpload} />
        <Route exact path="/dashboard/mapdand-update" component={MapdandUpdate} />
        <Route exact path="/dashboard/mapdand-update/:slug" component={MapdandSingleUpdate} />
        <Route exact path="/dashboard/mapdand-update/polygon/:slug" component={MapdandSinglePolyhonUpdate} />
        <Route exact path="/tst-admin" component={Admin} />
        {/* <Route exact path="/sendmessage" component={SendMessage} /> */}
        <Route exact path="/changepassword" component={ChangePassword} />
        {/* <Route exact path="/MapdandArea" component={MapdandArea} /> */}

        <Route exact path="/dashboard" component={Dashboard} />
         <Route component={PageNotfound} />
      </Switch>
      <Footer />
    </>
  );
}

function App() {
  const [loadingg, setloadingg] = useState(true);

   useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(TRACKING_ID);

    const timer = setTimeout(() => {
      setloadingg(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer position="top-center" />
     <Layout />
    </Router>
  );
}
 // "proxy": "http://localhost:8000/",

export default App;
