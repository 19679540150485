
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup , useMap,Polyline,Circle ,CircleMarker , Polygon ,useMapEvents , withLeaflet} from "react-leaflet";

import L from 'leaflet';
import 'leaflet-compass/dist/leaflet-compass.min.css';
import { Icon, divIcon, point } from "leaflet";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import 'leaflet/dist/leaflet.css';
import "leaflet-measure";
// import MeasureControl from 'react-leaflet-measure';
import './Leaflet.LinearMeasurement.css'; // Assuming you've placed the CSS file in the same folder
import './styles.css';
import './leaflet-measure-path.css'
// import './leaflet.MeasurePolygon.js';
import 'leaflet-draw/dist/leaflet.draw.css'; // Import Leaflet Draw CSS
import 'leaflet-draw'; // Import Leaflet Draw JS
import PinSearchControl from './PinSearchControl.jsx';
import './leaflet-measure.css'
// Import the plugin's JS file
import './Leaflet.LinearMeasurement.js';
import 'leaflet-compass';
import ReactGA from "react-ga4"; // Import ReactGA for Google Analytics
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import 'leaflet.locatecontrol'; 
import "leaflet-rotate"; // Import leaflet-rotate
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

import { allPolylines } from '../../action/polyline';
import { allPolygons } from '../../action/polygon';
import Description from '../MapdandUpload/Description.jsx';
// import 'leaflet-editable'; 
// create custom icon
const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/2238/2238263.png",
//   iconUrl: require("./icons/placeholder.png"),
  iconSize: [27, 27 , true] ,// size of the icon
 
});

const PinIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/535/535137.png",
//   iconUrl: require("./icons/placeholder.png"),
  iconSize: [27, 27 , true] ,// size of the icon
 
});



delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
});







const UseGeoSearch = () => {
  const map = useMap();
  
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar', // optional: default 'button', can be 'bar' for a full-width search bar
      showMarker: false, // optional: true|false  - default true
      showPopup: false, // optional: true|false  - default false
      marker: {
        icon: new L.Icon.Default(),
        draggable: false,
      },
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, [map]);
  
  return null;
};

const MapLocate = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const locateControl = L.control.locate({
      position: 'topleft',
      strings: {
        title: 'Show me where I am, yo!'
      },
      locateOptions: {
        enableHighAccuracy: true
      },
      showCompass: false, // Set to false if you do not want to show the compass
    }).addTo(map);

    // Optional: Handle events if needed
    map.on('locationfound', (e) => {
      console.log('Location found:', e);
    });

    map.on('locationerror', (e) => {
      console.error('Location error:', e);
    });

    return () => {
      map.removeControl(locateControl);
    };
  }, [map]);

  return null;
};





const MapMeasureControl = () => {
  const map = useMap();

  useEffect(() => {
    const cost_underground = 10;
    const cost_above_ground = 0;
    const html = [
      '<p>Total distance</p>',
      
    ].join('');
    const numberWithCommas = function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const Ruler = L.Control.LinearMeasurement.extend({
      layerSelected: function(e) {
        var distance = e.total.scalar;
        if (e.total.unit === 'mi') {
          distance *= e.sub_unit;
        } else if (e.total.unit === 'km') {
          distance *= 3280.84;
        } else if (e.total.unit === 'm') {
          distance *= 3.28084;
        }

        const data = {
          total_above_ground: numberWithCommas(L.Util.formatNum(cost_above_ground * distance, 2)),
          total_underground: numberWithCommas(L.Util.formatNum(cost_underground * distance, 2))
        };

        const content = L.Util.template(html, data);
        const popup = L.popup().setContent(content);

        e.total_label.bindPopup(popup, { offset: [45, 0] });
        e.total_label.openPopup();
      }
    });

    const measureControl = new Ruler({
      unitSystem: 'metric',
      color: '#FF0080'
    });

    map.addControl(measureControl);

    return () => {
      map.removeControl(measureControl);
    };
  }, [map]);

  return null;
};


const MapCompass = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const compassControl = new L.Control.Compass({
      autoActive: true,
      showDigit: true,
      textErr: 'Compass not available',
      callErr: (err) => {
        console.error('Compass error:', err); // Error handling
      },
      // angleOffset: 2, // Remove this line for testing
      position: 'topleft'
    });

    map.addControl(compassControl);

    // Ensure the compass bearing remains false
    map.on('compass:rotated', () => {
      compassControl.setAngle(0); // Reset the compass angle to 0
    });

    return () => {
      map.removeControl(compassControl);
    };
  }, [map]);

  return null;
};



const MeasurePolygonControl = () => {
  const map = useMap();

  useEffect(() => {
    const measurePolygonControl = new L.Control.MeasurePolygon({
      position: "topleft",
      icon_active: "https://img.icons8.com/?size=48&id=98497&format=png",
      icon_inactive: "https://img.icons8.com/?size=48&id=98463&format=png",
      color_polygon: "blue",
      fillColor_polygon: "green",
      weight_polygon: 5,
      msj_disable_tool: "Do you want to disable the tool?",
    });
    
    map.addControl(measurePolygonControl);
   


    return () => {
    
      map.removeControl(measurePolygonControl);
    };
  }, [map]);

  return null;
};

// markers
// const PolygonLayer = ({ polygons, handlePolygonClick }) => {
//   const [showMarkers, setShowMarkers] = useState(false);

//   useMapEvents({
//     zoomend: (e) => {
//       const zoomLevel = e.target.getZoom();
//       setShowMarkers(zoomLevel > 14);
//     }
//   });

//   return (
//     <>
//       {polygons && polygons.map((pg) => (
//         <Polygon
//           key={pg?._id}
//           positions={pg?.coordinate}
//           pathOptions={{ color: pg?.color, weight: 3 }}
//         >
//           {showMarkers && (
//             <Marker position={pg?.marker} icon={customIcon}>
//               <Popup>
//                 <h3 style={{ cursor: "pointer" }} onClick={() => handlePolygonClick(pg?._id)}>
//                   {pg?.title}
//                 </h3>
//                 <Description desc={pg?.desc} id={pg?._id} title="polygon" />
//               </Popup>
//             </Marker>
//           )}
//         </Polygon>
//       ))}
//     </>
//   );
// };

const PolygonLayer = ({ polygons, handlePolygonClick }) => {
  const [currentZoom, setCurrentZoom] = useState(null);

  useMapEvents({
    zoomend: (e) => {
      const zoomLevel = e.target.getZoom();
      setCurrentZoom(zoomLevel);
    }
  })

  const handlePolygonGAEvent = (pg) => {
    // Send an event to Google Analytics
    ReactGA.event({
      category: "Map Interaction",
      action: "Polyline Clicked",
      label: pg?.title, // Optional: Use the polyline's title as the label
      value: currentZoom // Optional: Send the current zoom level as the value
    });

    // Call the passed-in handlePolylineClick function
 
  };

  return (
    <>
      {polygons && polygons.map((pg) => (
        currentZoom >= pg?.zoom && (
          <Polygon
          key={pg?._id}
          positions={pg?.coordinate}
          pathOptions={{ color: pg?.color, weight: 3 }}
          eventHandlers={{
            click: () => handlePolygonGAEvent(pg) // Track click event
          }}
        >
          {currentZoom >= pg?.zoom && (
            <Marker position={pg?.marker} icon={customIcon}>
              <Popup>
                <h3 style={{ cursor: "pointer" }} >
                  {pg?.title}
                </h3>
                <Description desc={pg?.desc} id={pg?._id} title="polygon" />
              </Popup>
            </Marker>
          )}
        </Polygon>
        )
      ))}
    </>
  );
};

const PolylineLayer = ({ polylines, handlePolylineClick }) => {
  const [currentZoom, setCurrentZoom] = useState(null);

  useMapEvents({
    zoomend: (e) => {
      const zoomLevel = e.target.getZoom();
      setCurrentZoom(zoomLevel);
    }
  });
  const handlePolylineGAEvent = (pg) => {
    // Send an event to Google Analytics
    ReactGA.event({
      category: "Map Interaction",
      action: "Polyline Clicked",
      label: pg?.title, // Optional: Use the polyline's title as the label
      value: currentZoom // Optional: Send the current zoom level as the value
    });

    // Call the passed-in handlePolylineClick function
 
  };
  console.log(currentZoom)
  return (
    <>
      {polylines && polylines.map((pg) => (
     
          <Polyline
          key={pg?._id}
          positions={pg?.coordinate}
          pathOptions={{ color: pg?.color, weight: currentZoom >= 18 ? 20 : pg?.width , opacity: .7}}
          eventHandlers={{
            click: () => handlePolylineGAEvent(pg) // Track click event
          }}
        >
          {currentZoom >= pg?.zoom && (
           
              <Popup>
                <h3 style={{ cursor: "pointer" , fontSize:"16px" }}>
                  {pg?.title}
                </h3>
                <Description desc={pg?.desc} id={pg?._id} title="polyline" />
              </Popup>
            
          )}
        </Polyline>
      
      ))}
    </>
  );
};


function Mapdand({history}) {
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [polylines, setPolylines] = useState(null)
  const [polygons, setPolygons] = useState(null)
  const [showArea, setshowArea] = useState(false)
  const [plot, setPlot] = useState(null)


  console.log("polylines" , polylines)
  console.log("polygons" , polygons)
  console.log("plot" , plot)


  useEffect(() => {
    const filterPlotData = () => {
      const filteredPolylines = polylines?.map(pl => ({
        marker: pl.marker,
        title: pl.title,
        desc: pl.desc,
      }));

      const filteredPolygons = polygons?.map(pg => ({
        marker: pg.marker,
        title: pg.title,
        desc: pg.desc,
      }));

      return [...(filteredPolylines || []), ...(filteredPolygons || [])];
    };

    setPlot(filterPlotData());
  }, [polylines, polygons]);

  useEffect(() => {
    
    loadAllPolyline()
  
}, [])

useEffect(() => {
  const updateItemsPerPage = () => {
    if (window.innerWidth <= 768) {
      setshowArea(false)
    } else {
      setshowArea(true)
    }
  };

  // Initial check
  updateItemsPerPage();

  // Update on window resize
  window.addEventListener('resize', updateItemsPerPage);

  // Cleanup event listener on component unmount
  return () => window.removeEventListener('resize', updateItemsPerPage);
}, []);

const loadAllPolyline = async() =>{

  try {
    const polylineLoad = await allPolylines()
    const polygonLoad = await allPolygons()


    setPolylines(polylineLoad);
    setPolygons(polygonLoad)
  
  } catch (error) {
    
  }

}

  const handlePolylineClick = (id) =>{
    history.push(`/mapdand/line/${id}`)
  }
  
  const handlePolygonClick = (id) =>{
    history.push(`/mapdand/plot/${id}`)
  }



  return (
    <div className="mapdand-model">
       
         <MapContainer center={[27.63726087153388, 85.33303974897404]} zoom={13} minZoom={2}   rotate={true}
            
        
        touchRotate={false}
        compassBearing= {false}
        rotateControl={{
          closeOnZeroBearing: false , 
          position: 'bottomleft',
        }}
        
        
      
      >
      {/* OPEN STREEN MAPS TILES */}
      <TileLayer
          url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
          maxZoom={22}
          maxNativeZoom= {18}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          attribution= '&copy; TST Real Estate '
        />


     
                      <PolylineLayer polylines={polylines} handlePolylineClick={handlePolylineClick} />
                      <PolygonLayer polygons={polygons} handlePolygonClick={handlePolygonClick} />
        
          <MapMeasureControl />
          {
              showArea && <MeasurePolygonControl />
          }
          

     
     
          <MapLocate />
       <UseGeoSearch />
       <MapCompass/>
    </MapContainer>
    <div className="mapdand-content">
            <h1>जग्गा मापदण्ड</h1>
            <p> <span>NOTE</span>: All the informations are collected through careful inspections, several site visits, and detailed surveys. 
              Our skilled engineers and surveyors study current land prices, predict future changes, and evaluate other 
              advantages of the land. This thorough process ensures accurate and reliable information.</p>
            
        </div>
    </div>
   
  );
}


export default Mapdand