import React from 'react'
import './aboutus.css'

import { FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import profile1 from '../../assests/profile.jpeg'
import MetaData from "../../MetaData";

const teamMembers = [
  {
    id: 1 , 
    name: "Rajan Maharjan" , 
    work: "Engineer / Project Manager",
    number:  "whatsapp://send?phone=+9779706096060"
  },
  {
    id: 8 , 
    name: "John Maharjan" , 
    work: "Architect",
    number:  "whatsapp://send?phone=+9779845397005"
  },
  {
    id: 2 , 
    name: "Jeen Maharjan" , 
    work: "IT Adviser",
    number:  "whatsapp://send?phone=+9779845397005"
  },
  {
    id: 3 , 
    name: "Dipendra Maharjan" , 
    work: "Surveyor",
    number:  "whatsapp://send?phone=+9779851362144"
  },
  {
    id: 4 , 
    name: "Biki Maharjan" , 
    work: "Consultant",
    number:  "whatsapp://send?phone=+9779849448430"
  },
  {
    id: 5 , 
    name: "Disarp Maharjan" , 
    work: "Surveyor",
    number:  "whatsapp://send?phone=+9779761655210"
  },
  {
    id: 9 , 
    name: "Dinesh Maharjan" , 
    work: "Site Supervisor",
    number:  "whatsapp://send?phone=+9779840609982"
  },
  {
    id: 6 , 
    name: "Diya Maharjan" , 
    work: "Field Technician",
    number: "whatsapp://send?phone=+9779840609982"
  },
  {
    id: 7 , 
    name: "Sajeen Maharjan" , 
    work: "Field Technician",
    number:  "whatsapp://send?phone=+9779840609982"
  },
  {
    id: 10 , 
    name: "Construction Team" , 
    work: "Construction Workers",
    number:  "whatsapp://send?phone=+9779706096060"
  },
]


function AboutUs() {
  return (
    <>
    <MetaData title="About Us" />
      <div className='aboutus-model'>
          <div className="aboutRe-top">
            <h1>We are the people who make up TST Real Estate</h1>
            <p>Our philosophy is simple; hire great people and give them the resources and support 
              to do their best work.
            </p>
          </div>
          <div className="aboutRe-bottom">

            {
              teamMembers.map(t => (
                <div className="aboutRe-profile" key={t.id}>
              <img src={profile1} alt="" />
              <h3>{t.name}</h3>
              <p>{t.work}</p>
            </div>
              ))
            }
           
            
          </div>
      </div>
    </>
   
  )
}

{/* <div className="team-members">
                  {
                    teamMembers.map(t => (
                      <div className="each-member" key={t.id}>
                          <h2>{t.name}</h2>
                          <div className="member-div">
                            <div className="member-contact">
                           
                            <a href={t.number} target='_blank'><FaWhatsapp className='footer-social-icon'/></a>
                            <a href="mailto:tstrealestate1@gmail.com" target='_blank'><SiGmail className='footer-social-icon'/></a>
                            </div>
                          <img src={profile1} alt="" />
                          </div>
                          <h3>{t.work}</h3>
                      </div>
                    ))
                  }
                </div> */}

export default AboutUs