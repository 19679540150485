import React from 'react'
import logo from '../../assests/blog.jpg';
import {blogcontent} from './blogdata'
import './blogs.css'

function Blogs({history}) {

  const handleBlogsClick = (title) => {

    history.push(`/tstblogs/${title}`)
    
  
  };


  return (
    <div className='blogs-container'> 
        <h1>Read Blog Posts</h1>
        <div className="blogs-items">
          {
            blogcontent?.map((b) =>(
              <div className="blogs-box">
              <img src={b?.img} alt="" />
              <div className="blog-text">

             
                <h2>{b?.title}</h2>
                <hr />
                <button onClick={() =>{handleBlogsClick(b?.title)}}>Read Post</button>
                
              </div>
            </div>
            )
             
            )
          }
         

          
        </div>
    </div>
  )
}

export default Blogs