import React, {useRef, useEffect, useState ,useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Carousel from "react-images";
import '../Mapdand/leaflet.MeasurePolygon.js';
import { MapContainer, TileLayer, Marker, Popup , useMap,Polyline,Circle ,CircleMarker , Polygon } from "react-leaflet";
import { Button, Input } from 'antd';

import { FaLocationDot , FaArrowRightLong } from "react-icons/fa6";

import Accordion from '@mui/material/Accordion';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import MetaData from "../../MetaData";
import ReactPlayer from "react-player";
import emailjs from '@emailjs/browser';
import { FaAngleDoubleDown } from "react-icons/fa";
import AccordionSummary from '@mui/material/AccordionSummary';
import { toast } from 'react-toastify';
import { AddNotification } from '../../action/notification';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Icon, divIcon, point } from "leaflet";
import { Swiper, SwiperSlide } from 'swiper/react';
import './propertyDisplay.css'
import 'swiper/css';
import "leaflet-rotate"; // Import leaflet-rotate
import 'swiper/css/pagination';
import 'leaflet-draw/dist/leaflet.draw.css'; // Import Leaflet Draw CSS
import 'leaflet-draw'; // Import Leaflet Draw JS
import 'leaflet/dist/leaflet.css';
import "leaflet-measure";

import L from 'leaflet';
// import required modules
import { Pagination } from 'swiper/modules';
import { otherProperty, property } from '../../action/property';
import HomeDesc from '../Home/HomeDesc.jsx';





const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/2238/2238263.png",
//   iconUrl: require("./icons/placeholder.png"),
  iconSize: [27, 27 , true] ,// size of the icon
 
});

const mark = `For More Information

CONTACT OUR TEAM on Phone/ Viber / WhatsApp:

9851362144

9706096060

9761655210

TST Real Estate.


Email : tstrealestate1@gmail.com`

const MapMeasureControl = () => {
  const map = useMap();

  useEffect(() => {
    const cost_underground = 10;
    const cost_above_ground = 0;
    const html = [
      '<p>Total distance</p>',
      
    ].join('');
    const numberWithCommas = function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const Ruler = L.Control.LinearMeasurement.extend({
      layerSelected: function(e) {
        var distance = e.total.scalar;
        if (e.total.unit === 'mi') {
          distance *= e.sub_unit;
        } else if (e.total.unit === 'km') {
          distance *= 3280.84;
        } else if (e.total.unit === 'm') {
          distance *= 3.28084;
        }

        const data = {
          total_above_ground: numberWithCommas(L.Util.formatNum(cost_above_ground * distance, 2)),
          total_underground: numberWithCommas(L.Util.formatNum(cost_underground * distance, 2))
        };

        const content = L.Util.template(html, data);
        const popup = L.popup().setContent(content);

        e.total_label.bindPopup(popup, { offset: [45, 0] });
        e.total_label.openPopup();
      }
    });

    const measureControl = new Ruler({
      unitSystem: 'metric',
      color: '#FF0080'
    });

    map.addControl(measureControl);

    return () => {
      map.removeControl(measureControl);
    };
  }, [map]);

  return null;
};

const MeasurePolygonControl = () => {
  const map = useMap();

  useEffect(() => {
    const measurePolygonControl = new L.Control.MeasurePolygon({
      position: "topleft",
      icon_active: "https://img.icons8.com/?size=48&id=98497&format=png",
      icon_inactive: "https://img.icons8.com/?size=48&id=98463&format=png",
      color_polygon: "red",
      fillColor_polygon: "green",
      weight_polygon: 5,
      msj_disable_tool: "Do you want to disable the tool?",
    });

    map.addControl(measurePolygonControl);

    let touchStartTime;

    // Function to handle touchstart event
    const handleTouchStart = () => {
      touchStartTime = Date.now();
    };

    // Function to handle touchend event
    const handleTouchEnd = (e) => {
      const touchDuration = Date.now() - touchStartTime;
      if (touchDuration < 1000) {
        e.originalEvent.preventDefault();
        e.originalEvent.stopPropagation();
        measurePolygonControl._toggleMeasure();
      }
    };

    map.on('touchstart', handleTouchStart);
    map.on('touchend', handleTouchEnd);

    return () => {
      map.off('touchstart', handleTouchStart);
      map.off('touchend', handleTouchEnd);
      map.removeControl(measurePolygonControl);
    };
  }, [map]);

  return null;
};





function PropertyDisplay({history}) {
  const form = useRef();
  const { slug } = useParams();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [propertyTitle, setPropertyTitle] = useState("")
  const [propertyDesc, setPropertyDesc] = useState(``)
  const [contactDesc, setContactDesc] = useState('')
  const [images, setImages] = useState([])
  const [video, setVideo] = useState({})
  const [propertyLocation, setPropertyLocation] = useState("")
  const [polygonColor, setPolygonColor] = useState("blue")
  const [photoVisible, setPhotoVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [centroid, setCentroid] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sold, setSold] = useState(false)
  const [otherProperties, setOtherProperties] = useState([])
  const [mapLink, setMapLink] = useState("")
  const [showArea, setshowArea] = useState(false)

  let name = "27.630165459737935, 85.33743496709592"
  const encodedName = encodeURIComponent(name);
  const srcUrl = `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${encodedName}&t=k&z=18&ie=UTF8&iwloc=B&output=embed`;
  console.log(slug)
  const handleDescChange = (value) => {
    setContactDesc(value)
  }
  const handleNewarraivalClick = (title) =>{
    const bookingRoute = `/property/${title}`;
   
    window.location.href = bookingRoute;

  }

  useEffect(() => {


    loadProperty();
    loadOtherProperty()
}, [slug]);


useEffect(() => {
  const updateItemsPerPage = () => {
    if (window.innerWidth <= 768) {
      setshowArea(false)
    } else {
      setshowArea(true)
    }
  };

  // Initial check
  updateItemsPerPage();

  // Update on window resize
  window.addEventListener('resize', updateItemsPerPage);

  // Cleanup event listener on component unmount
  return () => window.removeEventListener('resize', updateItemsPerPage);
}, []);


const loadProperty = async () => {
try {
    const singleProperty = await property(slug);
    console.log(singleProperty);

    // Destructure the properties you need from singleProperty
    const {
        coordinate,
        title,
        images,
        video,
        location,
        desc,
        color,
        sold , 
        maplink
    } = singleProperty;

    // Populate the state variables accordingly
    setPolygonCoordinates(coordinate || []);
    setPropertyTitle(title);
    setImages(images);
    setVideo(video ? video : {});
    setPropertyLocation(location);
    setPropertyDesc(desc);
    setPolygonColor(color || 'blue'); // Default to 'blue' if color is not provided
    setSold(sold)
    setContactDesc(`I am interested in ${title}.`)
    setMapLink(maplink || '' )

} catch (error) {
    console.error('Error loading property:', error);
}
};

const loadOtherProperty = async () => {
  const other = await otherProperty(slug);
  setOtherProperties(other)
}


  const sendEmail = (e) => {
    e.preventDefault();

    if (!username || !email || !phone) {
      toast.info('Please fill in all fields.');
      return;
    }

      emailjs
      .sendForm('service_53rk9ej', 'template_ci9hd3p', form.current, {
        publicKey: 'pD1EZD3td_uFpCHPq',
      })
      .then(
        async() => {
          await AddNotification({
            title: `EMAIL FROM ${email}!!!!!!`,
            message: `${username} has mailed you. Contact to his number ${phone}`,
            
            read: false,
          } );
          toast.success('Email sent successfully!');
          setUsername("");
          setEmail("");
          setPhone("");

        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      
  };

  useEffect(() => {
    if (polygonCoordinates.length > 0) {
      calculateCentroid(polygonCoordinates);
    }
  }, [polygonCoordinates]);

  function calculateCentroid(polygonCoordinates) {
    if (!polygonCoordinates || polygonCoordinates.length === 0) {
      return setCentroid(null);
    }
  
    let x = 0;
    let y = 0;
    const length = polygonCoordinates.length;
  
    polygonCoordinates.forEach(([lat, lng]) => {
      x += lat;
      y += lng;
    });
  
    setCentroid([x / length, y / length]);
  }

  const openLightbox = useCallback((event, { image, index }) => {
    console.log(image)
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  const handleContactClick = () => {

    history.push("/contact")
   
  
  };
  return (
    <>
    {
      propertyTitle && (
        <div>
            <MetaData title={`${propertyTitle}`} />
    <div className='property-display-model'>
      <h1>{propertyTitle}</h1>
      <div className="project-display-main">
        <div className="project-display-left">
            <div className="project-slider">
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper1">
                      {
                        images?.map((image, index) => (
                          <SwiperSlide key={index} style={{cursor:"pointer"}}>
                            <img src={image?.Location} alt={propertyTitle} onClick={(e) => openLightbox(e, { images, index })} />
                          </SwiperSlide>
                        ))
                      }
                     
                    </Swiper>
            </div>
            <div className="property-descriotion">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<FaAngleDoubleDown />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{letterSpacing:"2px"}}
              >
                Property Details
              </AccordionSummary>
              <AccordionDetails>
                <Markdown className="property-details" remarkPlugins={[remarkGfm]}>{propertyDesc}</Markdown>
                {
                  mapLink.trim() !== "" &&  <a href={mapLink} target='_blank'><Button className='property-location-button'><FaLocationDot/> Click to view Google map<FaLocationDot/></Button></a>
                }
               
                
              </AccordionDetails>
            </Accordion>
           
            </div>
            {
              polygonCoordinates.length !== 0 && (
                <div className="property-map" >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<FaAngleDoubleDown />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{letterSpacing:"2px"}}
              >
                Property Location
              </AccordionSummary>
              <AccordionDetails className='map-width'>
              {centroid && centroid.length === 2 && !isNaN(centroid[0]) && !isNaN(centroid[1]) ? (
                <MapContainer center={centroid} zoom={17} minZoom={2} className='dasboard-map' scrollWheelZoom={showArea ? true : false}  rotate={false}
                  touchRotate={true}
                  style={{ width: '100%', height: '80vh' }}
           
 
    
                        rotateControl={{
                          closeOnZeroBearing: false
                        }}
                        shiftKeyRotate= {false}
                        >
                              <TileLayer
                                url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                                maxZoom={22}
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                attribution='&copy; TST Real Estate'
                              />
                                {polygonCoordinates.length > 0 && (
                                  <Polygon
                                    positions={polygonCoordinates}
                                    pathOptions={{ color: polygonColor, weight: 3 }}
                                    
                                  >
                                      <Marker position={centroid} icon={customIcon}>
                                
                                  </Marker>
                                  </Polygon>
                                  
                                )}
                                      <MapMeasureControl />
                                      {
                                    showArea &&  <MeasurePolygonControl />
                                  }
                        </MapContainer>
                      ) : (
                <div>Loading map...</div>
              )}

              </AccordionDetails>
            </Accordion>
            </div>
              )
            }
            
            {
              video && Object.keys(video).length !== 0 && 
                <div className="property-video">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<FaAngleDoubleDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{letterSpacing:"2px"}}
                  >
                    Property Video
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReactPlayer
                        // ref={videoRef}
                        url={video?.Location}
                        width="100%"
                        height="440px"
                        controls
                        />
                  </AccordionDetails>
                </Accordion>
                </div>
            }
            
            
            <div className="property-contact" >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<FaAngleDoubleDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Contact Our Team
              </AccordionSummary>
              <AccordionDetails>
                <Markdown className="property-contact" remarkPlugins={[remarkGfm]}>{mark}</Markdown>
              </AccordionDetails>
            </Accordion>
            </div>
        </div>

        <div className="property-display-contact">
          <div className="project-display-right">
           
           <h2>Contact Us For More Information</h2>
           <form ref={form} onSubmit={sendEmail}>
             
             <input type="text" placeholder='Full Name' name="name" 
             value={username} onChange={(e) => setUsername(e.target.value)} />
             <input type="number" placeholder='Phone Number' name="phone"
             value={phone} onChange={(e) => setPhone(e.target.value)} />
             <input type="email" placeholder='Email' name="email"
             value={email} onChange={(e) => setEmail(e.target.value)} />
             <textarea  id="" value={contactDesc} onChange={(e) => handleDescChange(e.target.value)} name="message"/>
             <label htmlFor="">For any inquiries or confusion, please call 9761655210. TST Real Estate is always here to guide you and provide assistance.</label>
             <button type="submit" value="Send">Send</button>
           </form>
          </div>
          <div className="other-property-model">
            <h3 style={{letterSpacing:"2px"}}>Other Properties</h3>
            {
                  otherProperties?.map(n => ( 
              <div className="cardRe property">
                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {
                          n?.images?.map(i => (
                            <SwiperSlide >
                              <img src={i?.Location} alt={n?.title} />
                            </SwiperSlide>
                          ))
                        }
                       
                      </Swiper>
               
                <div className="locationRe">
                  <h3>{n?.location}</h3>
                  <FaLocationDot className='locationIcon'/>
                </div>
                <div className="descRe">
                  <HomeDesc desc={n?.desc}/>
                
                </div>
                <hr />
                <div className="cardRe-buttons">
                  <button className='white-btn' onClick={() => handleNewarraivalClick(n?._id , n?.title)}>Show Details</button>
                  <button className='primary-btn'  onClick={() => handleContactClick()}>
                    Book a Tour <FaArrowRightLong/>
                  </button>
                </div>
              </div>
                 ))
                }
          </div>
        </div>
                     
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map(image => ({
                src: image?.Location || '', // Ensure src property is set
                srcset: '',
                caption: '',
                regular: '',
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
        </div>
      )
    }
    
    </>
    
  )
}

export default PropertyDisplay