import React, { useRef, useState } from 'react'
import MetaData from "../../MetaData";
import { FaLocationDot } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import { GiRotaryPhone } from "react-icons/gi";
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import './contact.css'
import { AddNotification } from '../../action/notification';

function ContactPage() {
  const form = useRef();
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [propertyDesc, setpropertyDesc] = useState(``)

  const sendEmail = (e) => {
    e.preventDefault();

    if (!username || !email || !phone || !propertyDesc) {
      toast.info('Please fill in all fields.');
      return;
    }

    emailjs
      .sendForm('service_53rk9ej', 'template_ci9hd3p', form.current, {
        publicKey: 'pD1EZD3td_uFpCHPq',
      })
      .then(
        async() => {
          await AddNotification({
            title: `EMAIL FROM ${email}!!!!!!`,
            message: `${username} has mailed you. Contact to his number ${phone}`,
            
            read: false,
          } );
          toast.success('Email sent successfully!');
          setUsername("");
          setEmail("");
          setPhone("");
          setpropertyDesc("")

        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      
  };
  const handleDescChange = (value) => {
    setpropertyDesc(value)
  }
  return (
    <>
      <MetaData title="Contact Us" />
      <div className='contact-model'>
          <div className="contactRe-left">
            <div className="contactRe-l-top">
              <h1>Contact Us</h1>
              <p>Email, call, or complete the form  for a free consultation.!</p>
              <h4>tstrealestate1@gmail.com</h4>
              <h4>9761655210 / 9706096060</h4>
            </div>
            <div className="contactRe-l-bottom">
              <div className="contactRe-info">
                <h3>Customer Support</h3>
                <p>Our support team is available around the clock 
                  to address any concerns or queries you may have.
                </p>
              </div>
              <div className="contactRe-info">
                <h3>Feedback and Suggestions</h3>
                <p>We value your feedback and are 
                  continuosly working to improve.
                  Your input is crucial in shaping 
                  the Future of TST Real Estate.
                </p>
              </div>
              <div className="contactRe-info">
                  <h3>Inquiries</h3>
                  <p>For questions or 
                    inquiries, please contact us.
                  </p>
              </div>
            </div>
          </div>
          <div className="contactRe-right">
            <form ref={form} onSubmit={sendEmail}>
              <h2>Get in Touch</h2>
              <p>You can reach us anytime.</p>
             
              <input type="text" placeholder='Enter Your Name'
              value={username} onChange={(e) => setUsername(e.target.value)} />
              <input type="number" placeholder='Enter Phone Number'
              value={phone} onChange={(e) => setPhone(e.target.value)} />
              <input type="email" placeholder='Enter Your Email' 
              value={email} onChange={(e) => setEmail(e.target.value)} />
              <textarea value={propertyDesc} rows={6} cols={30} onChange={(e) => handleDescChange(e.target.value)} name="message" ></textarea>
              <button type="submit" value="Send">Send</button>
          </form>
          </div>
      </div>
      <div style={{ width: '100%' }} className='contact-map'>
      <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=TST%20Real%20Estate+(TST%20Real%20Estate)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe>
      </div>
    </>
   
  )
}

export default ContactPage