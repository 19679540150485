import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Menu from '../Menu/Menu'
import MetaData from '../../MetaData'
import { useParams } from 'react-router-dom';
import {  toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode';
import { useSelector } from 'react-redux';
import { Button, Input, Modal } from 'antd';
import ImageModel from '../ModelImage/ImageModel';
import TextArea from 'antd/es/input/TextArea';
import { polygon, updatePolygon } from '../../action/polygon';

function MapdandSinglePolyhonUpdate({history}) {
  const { auth } = useSelector((state) => ({ ...state }));
  const [polygonImages, setPolygonImages] = useState([])
  const [polygonTitle, setPolygonTitle] = useState("")
  const { slug } = useParams();
  const [photoVisible, setPhotoVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [photoPolygonVisible, setPhotoPolygonVisible] = useState(false);
  const [polygonDescVisible, setPolygonDescVisible] = useState(false)
  const [polygonDesc, setPolygonDesc] = useState("")
  const [polygonWidth, setPolygonWidth] = useState(2)
  const [linewidth, setLinewidth] = useState(0)
  const [polygonColor, setPolygonColor] = useState("blue")
  const [inputValue, setInputValue] = useState("");
  const [zoomPolygon, setZoomPolygon] = useState(2)


  useEffect(() => {
    const isAuthInvalid = !auth 
  
    if (!isAuthInvalid) {
      const decodedToken = jwtDecode(auth.token);
      const currentTime = Date.now() / 1000; // in seconds
  
      if (decodedToken.exp < currentTime) {
        history.push('/tst-admin');
        return;
      }
    } else {
      history.push('/tst-admin');
    }
  }, [auth]);

  useEffect(() => {


    loadPolygon();

}, []);



const loadPolygon = async () => {
try {
    const singlePolyline = await polygon(slug);
    console.log(singlePolyline);

    // Destructure the properties you need from singlePolyline
    const {
        color,
        zoom,
        desc,
        images,
        title,
   
      
    } = singlePolyline;

    // Populate the state variables accordingly
  
    setPolygonTitle(title);
    setPolygonImages(images);
 
    setZoomPolygon(zoom)
    setPolygonDesc(desc);
    setPolygonColor(color || 'blue'); // Default to 'blue' if color is not provided
 
    

} catch (error) {
    console.error('Error loading property:', error);
}
};

const handlePolygonSubmit = async(e) => {
  e.preventDefault();

  
  if (
  
    polygonTitle.trim() === "" ||
   
    

    polygonDesc.trim() === "" ||
   
    polygonDesc.trim() === ""
  ) {
    alert("Please fill in all the fields.");
    return;
  }
  const polylineData = {
    title: polygonTitle,
    images: polygonImages,  // Make sure images are in the right format (e.g., URLs or base64)
     // Ensure video is in the correct format (e.g., URL)
    zoom: zoomPolygon ,
    desc: polygonDesc,

    color: polygonColor,  // Assuming this is the color you set
   
  
  };


  try {
    const updateData = await updatePolygon(slug , polylineData , auth?.token )
    if (updateData) {
      toast.success('Polygon updated successfully!');
      // Clear all form fields
      history.push("/dashboard/mapdand-upload")
    }
  } catch (error) {
    toast.error('Failed to upload property. Please try again.');
  }
};

const handleZoomPolygonInputChange = (e) => {
  const value = e.target.value;
  const numericValue = parseInt(value, 10);

  if (numericValue >= 1 && numericValue <= 20) {
    setZoomPolygon(numericValue);
  }
};

  
  return (
    <>
    <MetaData title="Dashboard" />
      <div className='dashboard-container'>
        <div className="dashboard-navbar">
          <Navbar/>
        </div>
        <div className="dashboard-bottom">
          <div className="menu-container">
              <Menu/>
          </div>
          <div className="dashboard-items">
            <h2>Title</h2>
            <div className="polyline-update">
                  <h3>Polyline</h3>
                 
                  <div className="mapdandUd-polyline-details">
                 
                  <Input className='mapdandUd-btn' value={polygonColor} onChange={(e) => setPolygonColor(e.target.value)}

                  placeholder='Color' />
                  <Input type="number" name="" max={20} min={1} value={zoomPolygon}   onChange={handleZoomPolygonInputChange}
                  className='form-input' placeholder='Zoom Level' id="" />
                  </div>
                  <Input type="text" name="" value={polygonTitle} onChange={(e) => setPolygonTitle(e.target.value)}
                  className='form-input' placeholder='Title' id="" />
                  <div className="mapdandUd-polyline-details">
                  <Button className='mapdandUd-btn' onClick={() => setPolygonDescVisible(true)} >
                    Polygon Details
                  </Button>
                  <Button className='mapdandUd-btn' onClick={() => setPhotoVisible(true)}>
                    Upload Photo
                  </Button>
                  </div>
                  <Button className='form-submit' onClick={handlePolygonSubmit}>Submit</Button>
                </div>
          </div>
        </div>
    </div>
    <ImageModel 
      photoVisible={photoVisible} 
      setPhotoVisible={setPhotoVisible} 
      images={polygonImages} 
      setImages={setPolygonImages} 
      loading={loading}
      setLoading={setLoading}
      slug={polygonTitle}
      id={slug}   
      />
    <Modal
              title="Description"
              centered
              open={polygonDescVisible}
              onCancel={() => setPolygonDescVisible(false)}
              footer={null}
            >
                <TextArea className='mandand-description' cols={10} rows={10} value={polygonDesc}
                onChange={(e) => setPolygonDesc(e.target.value)}
                placeholder='Enter Polyline Description'/>
            </Modal>
    </>
  )
}

export default MapdandSinglePolyhonUpdate