import React, { useState } from 'react';
import {useHistory} from 'react-router-dom'
import logo from '../../assests/TST logo.svg';
import './headermodel.css'

function HeaderModel() {
  const history = useHistory()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogoClick = () => {

    history.push("/")
    
  
  };

  const handleAboutClick = () => {

    history.push("/about-us")
 
  
  };

  const handlePropertyClick = () => {

    history.push("/property-list")
  
  
  };

  const handleEMIClick = () => {

    history.push("/emi-calculator")
  
  
  };

  const handleAREAClick = () => {

    history.push("/area-converter")
   
  
  };

  const handleMapdandClick = () => {

    history.push("/tstmap")
  
  
  };

  

  const handleContactClick = () => {

    history.push("/contact")
   
  
  };

  const handleBlogsClick = () => {

    history.push("/tstblogs")
    
  
  };

  const handleProductsHover = () => {
    setIsDropdownOpen(true);
  };

  const handleProductsLeave = () => {
    setIsDropdownOpen(false);
  };


  return (
    <div className='headerRe-container'>
        <div className="headerRe-left">
          <img src={logo} className='headerRe-logo' onClick={handleLogoClick} alt="" />
        </div>
        <div className="headerRe-left">
            <a onClick={handleLogoClick}>Home</a>
            <a onClick={handlePropertyClick}>Properties</a>
            <div
              className={`navLinks product`}
              onMouseEnter={handleProductsHover}
              onMouseLeave={handleProductsLeave}
            >
              Tools
              {isDropdownOpen && (
                <div className="dropdown-content">
                
                    <a href="#" onClick={() =>{handleMapdandClick()}}>जग्गा मापदण्ड</a>
                    <a href="#" onClick={() =>{handleAREAClick()}}>Area Convertor</a>
                    <a href="#" onClick={() =>{handleEMIClick()}}>EMI Calculator</a>
                </div>
              )}
            </div>
            <a onClick={handleBlogsClick}>Blogs</a>
            <a onClick={handleAboutClick}>About</a>
            <button onClick={handleContactClick}>Contact Us</button>
        </div>
    </div>
  )
}

export default HeaderModel