import { Button } from 'antd';
import {useHistory} from 'react-router-dom'
import React, { useState } from 'react';


import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function HomeDesc({ desc  }) {
    const [showMore, setShowMore] = useState(false);
    const wordLimit = 25;
    const words = desc.split(' ');
    const isLong = words.length > wordLimit;
    const displayedDesc = isLong && !showMore ? words.slice(0, wordLimit).join(' ') + ' . . . . . . . . . . . . . .' : desc;
  return (
    <ReactMarkdown className="homeRe-showmore" remarkPlugins={[remarkGfm]}>
        {displayedDesc}
      </ReactMarkdown>
  )
}

export default HomeDesc